import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaRegUserCircle, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import ROLE from '../../common/role';
import { CiEdit } from 'react-icons/ci';

function FreelancerDasboard() {
    const user = useSelector(state => state?.user?.user);
    const navigate = useNavigate();
    const [showMore, setShowMore] = useState(false);

    // Save role to localStorage on user change
    useEffect(() => {
        if (user?.role) {
            localStorage.setItem("userRole", user.role);
        }
    }, [user]);
    

    useEffect(() => {
        const role = user?.role 

        if (!role) {
            return; // Avoid premature redirect if role is not yet available
        }

        if (role !== ROLE.ADMIN && role !== ROLE.Freelancer) {
            navigate("/"); // Redirect unauthorized users
        }
    }, [user, navigate]);

    return (
        <div className='min-h-[calc(100vh-120px)] flex flex-col customMax-800:mt-[40px] md:flex-row'>
            <aside className='bg-white min-h-full w-full md:max-w-60 md:w-1/3 lg:w-1/4 customShadow'>
               {/* Profile Section */}
                <div className='h-35 flex justify-center items-center flex-col py-2'>
                <div className='flex text-5xl cursor-pointer relative justify-center'>
                    {
                    user?.profilePic ? (
                        <img src={user?.profilePic} className='w-20 h-20 rounded-full' alt={user?.name} />
                    ) : (
                        <FaRegUserCircle />
                    )
                    }
                </div>
                <p className='capitalize text-lg font-semibold'>{user?.name}</p>
                <div className='items-center justify-center flex flex-col'>
                    <p className='text-sm'>{user?.role}</p>
                    <div
                    className='w-fit bg-green-100 hover:bg-green-600 hover:text-white p-2 rounded cursor-pointer'
                    >
                    {/* Use Link from React Router for navigation */}
                    <Link to="/freelancer/update-account" className="flex items-center gap-1 text-green-600 hover:text-white">
                        <CiEdit className="text-lg" />
                        <span>Edit Profile</span>
                    </Link>
                    </div>
                </div>
                </div>


                {/* Navigation */}
                <div className='bg-slate-700 md:min-h-[calc(100vh-120px)] '>
                    <nav className='grid p-4  text-white gap-2 '>
                        <div className='grid gap-2 customMax-767:grid-cols-2'>
                            {/* Show the first four links */}
                            <Link to={"sold-products"} className='px-2 py-1 hover:bg-slate-100 border-2 border-e-4 border-pink-400 rounded hover:text-black'>Sold Products</Link>
                            <Link to={"pending-approval"} className='px-2 py-1 hover:bg-slate-100 border-2 border-e-4 border-blue-400 rounded hover:text-black'>Pending Approval</Link>
                            <Link to={"rejected-approval"} className='px-2 py-1 hover:bg-slate-100 border-2 border-e-4 rounded hover:text-black'>Rejected Approval</Link>
                        </div>

                        {/* Dropdown Section */}
                        <div className='relative mt-2  customMax-767:grid-cols-2'>
                            <button
                                onClick={() => setShowMore(!showMore)}
                                className='w-full text-left px-2 py-1 bg-slate-600 rounded text-white hover:bg-slate-500 flex justify-between items-center'
                            >
                                More Options
                                {showMore ? <FaChevronUp /> : <FaChevronDown />}
                            </button>

                            {/* Conditionally render additional links */}
                            {showMore && (
                                <div className='mt-2 grid gap-2'>
                                    <Link to={"transactions"} className='px-2 py-1 hover:bg-slate-100 border-2 border-e-4 border-green-400 rounded hover:text-black'>Money Summary</Link>
                                    
                                    <Link to={"create-faqs"} className='px-2 py-1 hover:bg-slate-100 border-2 border-e-4 border-yellow-600 rounded hover:text-black'>Create FAQ</Link>
                                    <Link to={"faqs"} className='px-2 py-1 hover:bg-slate-100 border-2 border-e-4 border-red-300 rounded hover:text-black'>Read Me</Link>
                                   
                                    
                                </div>
                            )}
                        </div>
                    </nav>
                </div>
            </aside>

            <main className='w-full h-full p-2'>
                <Outlet />
            </main>
        </div>
    );
}

export default FreelancerDasboard;
