
import React from 'react';

const CustomerNotice = ({ message, onClose }) => {
  return (
    <div className="customerNotice w-full bg-slate-800 text-orange-500 text-xl py-3 px-4 flex justify-between items-center customMax-800:mt-10 customMax-800:-mb-11">
      <p className="animate-pulse font-semibold">{message}</p> {/* Add animate-pulse for attention */}
      <button
        onClick={onClose}
        className="text-lg font-bold hover:text-gray-300 transition-colors text-white"
      >
        ✖
      </button>
    </div>
  );
};

export default CustomerNotice;
