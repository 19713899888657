import { useEffect, useState } from "react";
import summaryApi from "../common";
import { toast } from "react-toastify";


// const ExpandableRow = ({ product }) => {
//     const [expanded, setExpanded] = useState(false);
//     const [status, setStatus] = useState(product.status);
  
//     const handleStatusChange = async (newStatus) => {
//       try {
//         const response = await fetch(summaryApi.updatePurchaseProduct.url, {
//           method: summaryApi.updatePurchaseProduct.method,
//           credentials: "include",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//             purchaseId: product._id,
//             status: newStatus,
//           }),
//         });
  
//         const dataResponse = await response.json();
//         if (dataResponse.success) {
//           toast.success("Status updated successfully");
//           setStatus(newStatus);
//         } else {
//           toast.error(dataResponse.message);
//         }
//       } catch (error) {
//         console.error("Error updating status:", error);
//         toast.error("Failed to update status. Please try again.");
//       }
//     };

//     const buyerName = product?.buyerName || product?.buyerName; 
//     const buyerAddress = product?.buyerAddress || product?.buyerAddress

//     return (
//       <>
//         <tr>
//           <td className="border px-4 py-2">
//             <img
//               src={product.itemImage || "/placeholder.png"}
//               alt={product.itemName}
//               className="w-16 h-16 object-cover"
//             />
//           </td>
//           <td className="border px-4 py-2">{product.itemName}</td>
//           <td className="border px-4 py-2">{`purchaseId: ${product._id} productId: ${product?.productId}`}</td>
//           <td className="border px-4 py-2">{buyerName}</td>
//           <td className="border px-4 py-2">{buyerAddress}</td>
//           <td className="border px-4 py-2">
//             <select
//               value={status}
//               onChange={(e) => handleStatusChange(e.target.value)}
//               className="border rounded py-1 px-2"
//             >
              
//               <option value="dispatched">Dispatched</option>
//               <option value="pending">Pending</option>
//               <option value="delivered">Delivered</option>
//             </select>
//             {status}
//           </td>
//           <td className="border px-4 py-2">
//             <button
//               onClick={() => setExpanded(!expanded)}
//               className="text-blue-500 underline"
//             >
//               {expanded ? "Collapse" : "Expand"}
//             </button>
//           </td>
//         </tr>
//         {expanded && (
//           <tr>
//             <td colSpan="7" className="border px-4 py-2 bg-gray-50">
//               <p>status {status}</p>
//             </td>
//           </tr>
//         )}
//       </>
//     );
//   };

const ExpandableRow = ({ product, onStatusUpdate }) => {
  const [expanded, setExpanded] = useState(false);
  const [status, setStatus] = useState(product.status);

  // Sync status with parent updates (e.g., filters)
  useEffect(() => {
    setStatus(product.status);
  }, [product.status]);

  const handleStatusChange = async (newStatus) => {
    try {
      const response = await fetch(summaryApi.updatePurchaseProduct.url, {
        method: summaryApi.updatePurchaseProduct.method,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          purchaseId: product._id,
          status: newStatus,
        }),
      });

      const dataResponse = await response.json();
      if (dataResponse.success) {
        toast.success("Status updated successfully");
        setStatus(newStatus);

        // Notify parent about the status change
        if (onStatusUpdate) {
          onStatusUpdate(product._id, newStatus);
        }
      } else {
        toast.error(dataResponse.message);
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status. Please try again.");
    }
  };

  return (
    <>
      <tr>
        <td className="border px-4 py-2">
          <img
            src={product.itemImage || "/placeholder.png"}
            alt={product.itemName}
            className="w-16 h-16 object-cover"
          />
        </td>
        <td className="border px-4 py-2">{product.itemName}</td>
        <td className="border px-4 py-2">{`purchaseId: ${product._id} productId: ${product?.productId}`}</td>
        <td className="border px-4 py-2">{product.buyerName || "N/A"}</td>
        <td className="border px-4 py-2">{product.buyerAddress || "N/A"}</td>
        <td className="border px-4 py-2">{product.sellerMobile || "N/A"}</td>
        <td className="border px-4 py-2">
          <select
            value={status}
            onChange={(e) => handleStatusChange(e.target.value)}
            className="border rounded py-1 px-2"
          >
            <option value="pending">Pending</option>
            <option value="dispatched">Dispatched</option>
            <option value="delivered">Delivered</option>
          </select>
        </td>
        <td className="border px-4 py-2">
          <button
            onClick={() => setExpanded(!expanded)}
            className="text-blue-500 underline"
          >
            {expanded ? "Collapse" : "Expand"}
          </button>
        </td>
      </tr>
      {expanded && (
        <tr>
          <td colSpan="7" className="border px-4 py-2 bg-gray-50">
            <p>Status: {status}</p>
          </td>
        </tr>
      )}
    </>
  );
};


export default ExpandableRow;
