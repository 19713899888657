import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    freelancer : null
  }

const freelancerSlice = createSlice({
    name: 'freelancer',
    initialState,
    reducers: {
        setFreelancerDetails : (state, action) => {
            state.freelancer = action.payload
        }
 
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { setFreelancerDetails } = freelancerSlice.actions
  
  export default freelancerSlice.reducer