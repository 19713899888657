// import React, { useState } from 'react'
// import uploadImage from '../../helpers/uploadImage';
// import { FaCloudUploadAlt } from 'react-icons/fa';
// import summaryApi from '../../common';
// import { toast } from 'react-toastify';
// import { MdDelete } from 'react-icons/md';
// import DisplayImage from '../../components/DisplayImage';


// const UploadBanner = () => {
//     const [data, setData] = useState({
//         bannerName: "",
//         bannerImage : [],
//         market: "",
//     });
//     const [fullScreeImage, setFullScreenImage] = useState("")
//     const [openFullScreenImage, setOpenFullScreenImage] = useState(false)

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setData((prevData) => ({
//             ...prevData,
//             [name]: value
//         }));
//     };


//     // Cloudinary imageUploadFuntion
//     const handleUploadProduct = async(e) =>{
//         const files = Array.from(e.target.files); // Get all selected files
//         const uploadImageCloudinary = await Promise.all(
//             files.map(file => uploadImage(file)) // Upload all files to Cloudinary
//         );

//         const imageUrls = uploadImageCloudinary.map(response => response.url); // Extract URLs from the responses

//         setData((preve) =>{
//             return { 
//                 ...preve,
//                 bannerImage : [...preve.bannerImage, ...imageUrls ]
//             }

//         })
//     }

//     // Azure blob container image uploadFunction
//     // const handleUploadProduct = async (e) => {
//     //     const files = Array.from(e.target.files); // Get all selected files
      
//     //     // Create FormData to send files to the backend
//     //     const formData = new FormData();
//     //     files.forEach((file) => formData.append("files", file));
      
//     //     try {
//     //       // Send the files to the backend for uploading
//     //       const response = await fetch(summaryApi.imageUpload.url, {
//     //         method: summaryApi.imageUpload.method,
//     //         body: formData,
//     //       });
      
//     //       if (!response.ok) {
//     //         throw new Error("Failed to upload images");
//     //       }
      
//     //       // Get the image URLs from the backend
//     //       const imageUrls = await response.json();
      
//     //       // Update state with the new image URLs
//     //       setData((preve) => {
//     //         return {
//     //           ...preve,
//     //           productImage: [...preve.productImage, ...imageUrls],
//     //         };
//     //       });
      
//     //       //console.log("Uploaded Images:", imageUrls);
//     //     } catch (error) {
//     //       console.error("Error uploading images:", error.message);
//     //     }
//     // };
      

//     const handleDeleteProductImage = async(index) =>{
//        const newProductImage = [...data.productImage]
//        newProductImage.splice(index,1)

//        setData((preve) => {
//         return {
//             ...preve,
//             productImage : [...newProductImage]
//         }
//        })
//     }



//     const handleSubmit = async(e) =>{
//         e.preventDefault()
    
//         const productSumbit = await fetch(summaryApi.createBanner.url, {
//             method : summaryApi.createBanner.method,
//             credentials : "include",
//             headers : {
//                 "content-type" : "application/json"
//             },
//             body : JSON.stringify(data)
//         })

//         const dataApi = await productSumbit.json()
//         console.log('banner response:', dataApi)

//         if(dataApi.success){
//             toast.success(dataApi?.message)
//             setData("")
//             //fetchProducts();
//             //onClose()
//         }else{
//             toast.error(dataApi?.message)
//         }

//     }

//     //fetch banner
//     const fetchBanner = async ({ bannerName }) => {
//         try {
//           // Get market from localStorage
//           const market = localStorage.getItem('localMarket');
          
//           // Build query string with bannerName and market
//           const queryParams = new URLSearchParams();
//           if (bannerName) queryParams.append("bannerName", bannerName);
//           if (market) queryParams.append("market", market);
      
//           // Construct the fetch URL
//           const url = `${summaryApi.fetchBanner.url}?${queryParams.toString()}`;
//           const response = await fetch(url, {
//             method: summaryApi.fetchBanner.method,
//           });
      
//           if (!response.ok) {
//             throw new Error(`Error fetching banners: ${response.statusText}`);
//           }
      
//           const data = await response.json();
//           return data;
//         } catch (error) {
//           console.error("Failed to fetch banners:", error);
//           throw error;
//         }
//       };
      
//   return (
//     <div>
//         <form className='grid p-4 gap-2 font-semibold' onSubmit={handleSubmit}>
//             <h2>Upload Banner</h2>
//             <div>
//                 <input
//                 type='text'
//                 name='bannerName'
//                 value={data.bannerName}
//                 placeholder='Name Banner'
//                 onChange={handleChange}
//                 className='p-2 bg-slate-100 border rounded'
//                 />
//             </div>

//             <div className='flex gap-7 items-center mt-3'>
//                 <label className=" flex" htmlFor='market'> Market Banner:</label>
//                 <div>
//                     <select
//                         id='market'
//                         name='market'
//                         value={data.market}
//                         onChange={handleChange}
//                         className='p-2 bg-slate-100 border rounded mr-2'
//                         required
//                     >
//                         <option value=''>Select</option>
//                         <option value='India'>India</option>
//                         <option value='Nigeria'>Nigeria</option>
                        
//                     </select>
//                 </div>
//             </div>

//             <label htmlFor='bannerImage' className='mt-3'>Banner Images</label>
//             <label htmlFor='uploadImageInput'>
//                 <div className='p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center'>
//                     <div className='text-slate-400 flex flex-col justify-center items-center cursor-pointer'>
//                         <span className='text-3xl'> <FaCloudUploadAlt/> </span>
//                         <p>Upload Banner</p>
//                         <input type='file' id='uploadImageInput' className='hidden' multiple onChange={handleUploadProduct}/>
//                     </div>
//                 </div>
//             </label>
//             <div>
//                 {
//                         data?.bannerImage[0] ? (
//                         <div className='flex items-center gap-3'>
                            
//                             { data.bannerImage.map((el, index )=>{
//                                     return (
//                                         <div className='relative group'>
//                                             <img src={el} 
//                                                 alt={el} 
//                                                 width={80} 
//                                                 height={80} 
//                                                 className='bg-slate-100 border cursor-pointer'
//                                                 required
//                                                 onClick={() => {
//                                                 setOpenFullScreenImage(true)
//                                                 setFullScreenImage(el)
//                                             }} />

//                                             <div className='bg-orange-500 text-white rounded-full cursor-pointer absolute bottom-0 right-0 p-1 hidden group-hover:block' onClick={() => handleDeleteProductImage(index)}> 
//                                                 <MdDelete /> 
//                                             </div>
//                                         </div>
                                           
//                                     )
//                             })}
//                         </div>
//                     )
                    
//                     : 
//                     (
//                         <p className='text-red-600 text-xs'> *please upload banner image* </p>
//                     )
//                 }
                
//             </div>

//             <button className='px-3 py-2 bg-orange-500 text-white mt-7 rounded-full hover:bg-orange-600'>
//                     Upload Banner
//             </button>

//         </form>

//             {/**display image full screen */}
//             {  openFullScreenImage && (
//                 <DisplayImage 
//                 onClose={()=> setOpenFullScreenImage(false)} 
//                 imgUrl={fullScreeImage}/>
//             )}

//             {/* list all bannerName here */}
//     </div>      
//   )
// }

// export default UploadBanner

import React, { useState, useEffect } from "react";
import uploadImage from "../../helpers/uploadImage";
import { FaCloudUploadAlt } from "react-icons/fa";
import summaryApi from "../../common";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import DisplayImage from "../../components/DisplayImage";

const UploadBanner = () => {
  const [data, setData] = useState({
    bannerName: "",
    bannerImage: [],
    market: "",
  });
  const [banners, setBanners] = useState([]); // To store fetched banners
  const [fullScreenImage, setFullScreenImage] = useState("");
  const [openFullScreenImage, setOpenFullScreenImage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUploadProduct = async (e) => {
    const files = Array.from(e.target.files); // Get all selected files
    const uploadImageCloudinary = await Promise.all(
      files.map((file) => uploadImage(file)) // Upload all files to Cloudinary
    );

    const imageUrls = uploadImageCloudinary.map((response) => response.url); // Extract URLs from the responses

    setData((prev) => ({
      ...prev,
      bannerImage: [...prev.bannerImage, ...imageUrls],
    }));
  };

  const handleDeleteProductImage = (index) => {
    const newBannerImage = [...data.bannerImage];
    newBannerImage.splice(index, 1);

    setData((prev) => ({
      ...prev,
      bannerImage: [...newBannerImage],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const productSubmit = await fetch(summaryApi.createBanner.url, {
      method: summaryApi.createBanner.method,
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const dataApi = await productSubmit.json();
    console.log("banner response:", dataApi);

    if (dataApi.success) {
      toast.success(dataApi?.message);
      setData({ bannerName: "", bannerImage: [], market: "" });
      fetchBanners(); // Fetch updated banners list after successful submission
    } else {
      toast.error(dataApi?.message);
    }
  };

  // Fetch banners list
  const fetchBanners = async () => {
    try {
      const url = summaryApi.fetchBanner.url; // Fetch all banners without filtering by market
      const response = await fetch(url, {
        method: summaryApi.fetchBanner.method,
      });

      if (!response.ok) {
        throw new Error(`Error fetching banners: ${response.statusText}`);
      }

      const returnBanner = await response.json();
      setBanners(returnBanner.data || []); // Assuming `banners` is the key for the list of banners
    } catch (error) {
      console.error("Failed to fetch banners:", error);
    }
  };

  useEffect(() => {
    fetchBanners(); // Fetch banners on component mount
  }, []);

  return (
    <div className="mx-auto md:w-[85%] h-[100vh] overflow-auto">
      <form className="grid p-4 gap-2 font-semibold" onSubmit={handleSubmit}>
        <h2>Upload Banner</h2>
        <div>
          <input
            type="text"
            name="bannerName"
            value={data.bannerName}
            placeholder="Name Banner"
            onChange={handleChange}
            className="p-2 bg-slate-100 border rounded"
          />
        </div>

        <div className="flex gap-7 items-center mt-3">
          <label className="flex" htmlFor="market">
            Market Banner:
          </label>
          <div>
            <select
              id="market"
              name="market"
              value={data.market}
              onChange={handleChange}
              className="p-2 bg-slate-100 border rounded mr-2"
              required
            >
              <option value="">Select</option>
              <option value="India">India</option>
              <option value="Nigeria">Nigeria</option>
            </select>
          </div>
        </div>

        <label htmlFor="bannerImage" className="mt-3">
          Banner Images
        </label>
        <label htmlFor="uploadImageInput">
          <div className="p-2 bg-slate-100 border rounded h-32 w-full flex justify-center items-center">
            <div className="text-slate-400 flex flex-col justify-center items-center cursor-pointer">
              <span className="text-3xl">
                <FaCloudUploadAlt />
              </span>
              <p>Upload Banner</p>
              <input
                type="file"
                id="uploadImageInput"
                className="hidden"
                multiple
                onChange={handleUploadProduct}
              />
            </div>
          </div>
        </label>
        <div>
          {data?.bannerImage[0] ? (
            <div className="flex items-center gap-3">
              {data.bannerImage.map((el, index) => (
                <div className="relative group" key={index}>
                  <img
                    src={el}
                    alt="Banner"
                    width={80}
                    height={80}
                    className="bg-slate-100 border cursor-pointer"
                    required
                    onClick={() => {
                      setOpenFullScreenImage(true);
                      setFullScreenImage(el);
                    }}
                  />
                  <div
                    className="bg-orange-500 text-white rounded-full cursor-pointer absolute bottom-0 right-0 p-1 hidden group-hover:block"
                    onClick={() => handleDeleteProductImage(index)}
                  >
                    <MdDelete />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-red-600 text-xs">
              *please upload banner image*
            </p>
          )}
        </div>

        <button className="px-3 py-2 bg-orange-500 text-white mt-7 rounded-full hover:bg-orange-600">
          Upload Banner
        </button>
      </form>

      {/** Display full-screen image */}
      {openFullScreenImage && (
        <DisplayImage
          onClose={() => setOpenFullScreenImage(false)}
          imgUrl={fullScreenImage}
        />
      )}

      {/** List all uploaded banners */}
      <div className="mt-10">
        <h3 className="font-semibold mb-4">Uploaded Banners</h3>
        {banners.length > 0 ? (
          <ul className="space-y-2">
            {banners.map((banner, index) => (
              <li key={index} className="border p-2 rounded bg-slate-100">
                <p>Banner Name: {banner.bannerName}</p>
                <p>Market: {banner.market}</p>
                {banner.bannerImage && banner.bannerImage.length > 0 && (
                  <div className="flex gap-2 mt-2">
                    {banner.bannerImage.map((image, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={image}
                        alt="Banner"
                        width={50}
                        height={50}
                        className="border rounded"
                      />
                    ))}
                  </div>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p>No banners uploaded yet.</p>
        )}
      </div>
    </div>
  );
};

export default UploadBanner;
