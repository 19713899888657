// import React, { useState, useEffect } from "react";
// import summaryApi from "../common";
// import { useParams } from "react-router-dom";
// import { toast } from "react-toastify";

// const FreelancerPage = () => {
//   const { _id } = useParams();
//   const [freelancer, setFreelancer] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [showExperience, setShowExperience] = useState(false);
//   const [hireData, setHireData] = useState({
//     freelancerId: _id,
//     hireType: "",
//     duration: "",
//     amount: "",
//   });

//   const fetchFreelancerDetail = async () => {
//     try {
//       const response = await fetch(`${summaryApi.getFreelancerDetail.url}/${_id}`, {
//         method: "GET",
//         credentials: "include",
//         headers: { "Content-Type": "application/json" },
//       });
//       if (!response.ok) {
//         throw new Error(`HTTP error! Status: ${response.status}`);
//       }
//       const responseData = await response.json();
//       if (responseData.success) {
//         setFreelancer(responseData.data);
//       } else {
//         toast.error(responseData.message);
//       }
//     } catch (err) {
//       console.error("Error fetching freelancer details:", err);
//       toast.error("Failed to fetch freelancer details");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchFreelancerDetail();
//   }, [_id]);

//   const handleHireFreelancer = async () => {
//     if (!hireData.hireType || !hireData.amount) {
//       toast.error("Please fill all required fields!");
//       return;
//     }
//     try {
//       const response = await fetch(summaryApi.hireFreelancer.url, {
//         method: "POST",
//         credentials: "include",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(hireData),
//       });
//       const responseData = await response.json();
//       if (response.ok) {
//         toast.success("Hire request sent successfully!");
//       } else {
//         toast.error(responseData.message || "Failed to hire freelancer");
//       }
//     } catch (error) {
//       console.error("Error hiring freelancer:", error);
//       toast.error("Unexpected error occurred while hiring");
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setHireData((prev) => ({ ...prev, [name]: value }));
//   };

//   if (loading) return <div className="text-center py-20">Loading...</div>;

//   return (
//     <div className="font-sans">
//       {/* Wall Picture Section */}
//       <div className="relative">
//         <img
//           src={freelancer?.wallPicture || "/default-wall.jpg"}
//           alt="Wall Picture"
//           className="w-full h-[320px] object-cover"
//         />
//         <div className="absolute bottom-[-60px] right-10">
//           <img
//             src={freelancer?.profilePic || "/default-profile.png"}
//             alt={`${freelancer?.name}'s Profile`}
//             className="w-[120px] h-[120px] rounded-full border-4 border-white shadow-md"
//           />
//         </div>
//       </div>

//       {/* Profile Details Section */}
//       <div className="px-6 py-8">
//         <div className="flex flex-col items-start">
//           <h2 className="text-3xl font-bold">{freelancer?.name}</h2>
//           <p className="text-gray-600 mt-2"><strong>Work Title:</strong> {freelancer?.workTitle}</p>
//           <p className="text-gray-600 mt-1"><strong>Skills:</strong> {freelancer?.skills?.join(", ")}</p>
//           <div className="mt-4">
//             <p className="text-lg font-semibold">Rates:</p>
//             <ul className="text-gray-600">
//                 <li>
//                 Hourly: {freelancer?.currency} {freelancer?.hourlyRate}
//                 </li>
//                 <li>
//                 Weekly: {freelancer?.currency} {freelancer?.weeklyRate}
//                 </li>
//                 <li>
//                 Monthly: {freelancer?.currency} {freelancer?.monthlyRate}
//                 </li>
//             </ul>
//         </div>

//         </div>

//         {/* Experience Section */}
//         <div className="mt-6">
//           <button
//             className="px-4 py-2 bg-blue-500 text-white rounded shadow hover:bg-blue-600"
//             onClick={() => setShowExperience(!showExperience)}
//           >
//             {showExperience ? "Hide Experience" : "View Experience"}
//           </button>
//           {showExperience && (
//             <div className="mt-4">
//               <h3 className="text-xl font-semibold">Experience</h3>
//               <ul className="list-disc pl-6 mt-2 text-gray-600">
//                 {freelancer?.experiences?.map((exp, index) => (
//                   <li key={index}>{exp}</li>
//                 ))}
//               </ul>
//               <h3 className="text-xl font-semibold mt-4">Education</h3>
//               <ul className="list-disc pl-6 mt-2 text-gray-600">
//                 {freelancer?.education?.map((edu, index) => (
//                   <li key={index}>{edu}</li>
//                 ))}
//               </ul>
//               <h3 className="text-xl font-semibold mt-4">Qualifications</h3>
//               <ul className="list-disc pl-6 mt-2 text-gray-600">
//                 {freelancer?.qualifications?.map((qual, index) => (
//                   <li key={index}>{qual}</li>
//                 ))}
//               </ul>
//             </div>
//           )}
//         </div>

//         {/* Hire Section */}
//         <div className="mt-8">
//           <h3 className="text-xl font-bold">Hire {freelancer?.name}</h3>
//           <div className="flex items-center gap-4 mt-4">
//             <select
//               name="hireType"
//               onChange={handleInputChange}
//               value={hireData.hireType}
//               className="border rounded px-3 py-2"
//             >
//               <option value="">Select Hire Type</option>
//               <option value="hourly">Hourly</option>
//               <option value="weekly">Weekly</option>
//               <option value="monthly">Monthly</option>
//             </select>
//             {hireData.hireType !== "monthly" && (
//               <input
//                 type="number"
//                 name="amount"
//                 placeholder={`Amount in ${freelancer?.currency}`}
//                 value={hireData.amount}
//                 onChange={handleInputChange}
//                 className="border rounded px-3 py-2"
//               />
//             )}
//             <input
//               type="number"
//               name="amount"
//               placeholder="Total Amount"
//               value={hireData.amount}
//               onChange={handleInputChange}
//               className="border rounded px-3 py-2"
//             />
//             <button
//               onClick={handleHireFreelancer}
//               className="px-4 py-2 bg-green-500 text-white rounded shadow hover:bg-green-600"
//             >
//               Hire {freelancer?.name}
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FreelancerPage;


import React, { useState, useEffect } from "react";
import summaryApi from "../common";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";


const FreelancerPage = () => {
  const { _id } = useParams();
  const [freelancer, setFreelancer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showExperience, setShowExperience] = useState(false);
  const [hireData, setHireData] = useState({
    freelancerId: _id,
    hireType: "",
    duration: "",
    amount: "",
  });
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    fetchFreelancerDetail();
    fetchOffers();
  }, [_id]);

  const fetchFreelancerDetail = async () => {
    try {
      const response = await fetch(`${summaryApi.getFreelancerDetail.url}/${_id}`, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      if (responseData.success) {
        setFreelancer(responseData.data);
      } else {
        toast.error(responseData.message);
      }
    } catch (err) {
      console.error("Error fetching freelancer details:", err);
      toast.error("Failed to fetch freelancer details");
    } finally {
      setLoading(false);
    }
  };

  const fetchOffers = async () => {
    try {
      const response = await fetch(summaryApi.getOffers.url, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      });

      const data = await response.json();
      if (data.success) {
        setOffers(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error fetching offers:", error);
    }
  };

  const handleAcceptOffer = async (offerId) => {
    try {
      const response = await fetch(summaryApi.acceptHire.url, {
        method: summaryApi.acceptHire.method,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ offerId }),
      });
      const responseData = await response.json();
      if (responseData.success) {
        toast.success("Offer accepted successfully!");
        fetchOffers();
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error("Error accepting offer:", error);
      toast.error("Failed to accept the offer");
    }
  };

  const handleRejectOffer = async (offerId) => {
    try {
      const response = await fetch(summaryApi.rejectHire.url, {
        method: summaryApi.rejectHire.method,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ offerId }),
      });
      const responseData = await response.json();
      if (responseData.success) {
        toast.success("Offer rejected successfully!");
        fetchOffers();
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error("Error rejecting offer:", error);
      toast.error("Failed to reject the offer");
    }
  };

  const handleHireFreelancer = async () => {
    if (!hireData.hireType || !hireData.amount) {
      toast.error("Please fill all required fields!");
      return;
    }
    try {
      const response = await fetch(summaryApi.hireFreelancer.url, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(hireData),
      });
      const responseData = await response.json();
      if (response.ok) {
        toast.success("Hire request sent successfully!");
      } else {
        toast.error(responseData.message || "Failed to hire freelancer");
      }
    } catch (error) {
      console.error("Error hiring freelancer:", error);
      toast.error("Unexpected error occurred while hiring");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHireData((prev) => ({ ...prev, [name]: value }));
  };

  if (loading) return <div className="text-center py-20">Loading...</div>;

  return (
    <div className="font-sans">
      {/* Wall Picture Section */}
      <div className="relative">
        <img
          src={freelancer?.wallPicture || "/default-wall.jpg"}
          alt="Wall Picture"
          className="w-full h-[320px] object-cover"
        />
        <div className="absolute bottom-[-60px] right-10">
          <img
            src={freelancer?.profilePic || "/default-profile.png"}
            alt={`${freelancer?.name}'s Profile`}
            className="w-[120px] h-[120px] rounded-full border-4 border-white shadow-md"
          />
        </div>
      </div>

      {/* Profile Details Section */}
      <div className="px-6 py-8">
        {/* Profile Details Section */}
        <div className="px-6 py-8">
    <div className="flex flex-col items-start">
    <h2 className="text-3xl font-bold">{freelancer?.name}</h2>
    <p className="text-gray-600 mt-2"><strong>Work Title:</strong> {freelancer?.workTitle}</p>
    <p className="text-gray-600 mt-1"><strong>Skills:</strong> {freelancer?.skills?.join(", ")}</p>
    <div className="mt-4">
        <p className="text-lg font-semibold">Rates:</p>
        <ul className="text-gray-600">
            <li>
            Hourly: {freelancer?.currency} {freelancer?.hourlyRate}
            </li>
            <li>
            Weekly: {freelancer?.currency} {freelancer?.weeklyRate}
            </li>
            <li>
            Monthly: {freelancer?.currency} {freelancer?.monthlyRate}
            </li>
        </ul>
    </div>

    </div>

    {/* Experience Section */}
        <div className="mt-6">
        <button
            className="px-4 py-2 bg-blue-500 text-white rounded shadow hover:bg-blue-600"
            onClick={() => setShowExperience(!showExperience)}
        >
            {showExperience ? "Hide Experience" : "View Experience"}
        </button>
        {showExperience && (
            <div className="mt-4">
            <h3 className="text-xl font-semibold">Experience</h3>
            <ul className="list-disc pl-6 mt-2 text-gray-600">
                {freelancer?.experiences?.map((exp, index) => (
                <li key={index}>{exp}</li>
                ))}
            </ul>
            <h3 className="text-xl font-semibold mt-4">Education</h3>
            <ul className="list-disc pl-6 mt-2 text-gray-600">
                {freelancer?.education?.map((edu, index) => (
                <li key={index}>{edu}</li>
                ))}
            </ul>
            <h3 className="text-xl font-semibold mt-4">Qualifications</h3>
            <ul className="list-disc pl-6 mt-2 text-gray-600">
                {freelancer?.qualifications?.map((qual, index) => (
                <li key={index}>{qual}</li>
                ))}
            </ul>
            </div>
        )}
        </div>

            {/* Hire Section */}
        <div className="mt-8">
        <h3 className="text-xl font-bold">Hire {freelancer?.name}</h3>
        <div className="flex items-center gap-4 mt-4">
            <select
            name="hireType"
            onChange={handleInputChange}
            value={hireData.hireType}
            className="border rounded px-3 py-2"
            >
            <option value="">Select Hire Type</option>
            <option value="hourly">Hourly</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            </select>
            {hireData.hireType !== "monthly" && (
            <input
                type="number"
                name="amount"
                placeholder={`Amount in ${freelancer?.currency}`}
                value={hireData.amount}
                onChange={handleInputChange}
                className="border rounded px-3 py-2"
            />
            )}
            <input
            type="number"
            name="amount"
            placeholder="Total Amount"
            value={hireData.amount}
            onChange={handleInputChange}
            className="border rounded px-3 py-2"
            />
            <button
            onClick={handleHireFreelancer}
            className="px-4 py-2 bg-green-500 text-white rounded shadow hover:bg-green-600"
            >
            Hire {freelancer?.name}
            </button>
        </div>
            </div>
        </div>

        {/* Offers Section */}
        <div className="mt-10">
          <h3 className="text-xl font-semibold mb-4">Offers</h3>
          {offers.length === 0 ? (
            <p className="text-gray-500">No offers at the moment.</p>
          ) : (
            <div className="grid grid-cols-1 gap-4">
              {offers.map((offer) => (
                <div
                  key={offer._id}
                  className="p-4 border rounded-md shadow-md bg-white"
                >
                  <p className="text-gray-700 font-bold">
                    From: {offer.customerName}
                  </p>
                  <p className="text-gray-600">
                    Offer Type: {offer.hireType} ({offer.duration})
                  </p>
                  <p className="text-gray-600">
                    Amount: {freelancer?.currency} {offer.amount}
                  </p>
                  <div className="flex items-center gap-4 mt-2">
                    <button
                      onClick={() => handleAcceptOffer(offer._id)}
                      className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                    >
                      Accept
                    </button>
                    <button
                      onClick={() => handleRejectOffer(offer._id)}
                      className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                    >
                      Reject
                    </button>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    >
                      Message Customer
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FreelancerPage;

