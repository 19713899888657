
const ServiceCategory = [
    {id : 1, label : "Writing and Content Creation", value: "Writing and Content Creation"}, 
    {id : 2, label : "Graphic Design", value : "Graphic Design"}, 
    {id : 3, label : "Web and Software Development", value : "Web and Software Development"},
    {id : 4, label : "Digital Marketing", value : "Digital Marketing"}, 
    {id : 5, label : "Photography and Videography", value : "Photography and Videography"}, 
    {id : 6, label : "Consulting", value : "Consulting"}, 
    {id : 7, label : "Education and Training", value : "Education and Training"},
    {id : 8, label : "Administrative Support", value : "Administrative Support"}, 
    {id : 9, label : "Art and Craft", value : "Art and Craft"}, 
    {id : 10, label : "Health and Wellness", value : "Health and Wellness"}, 
    {id : 11, label : "Technology and IT", value : "Technology and IT"},
    {id : 12, label : "Finance and Accounting", value : "Finance and Accounting"}, 
    {id : 13, label : "Music and Audio", value : "Music and Audio"}, 
    {id : 14, label : "Real Estate Services", value : "Real Estate Services"}
]


export default ServiceCategory

