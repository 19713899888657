import React from 'react'
import { IoIosCloseCircleOutline } from "react-icons/io";

const DisplayImage = ({
    imgUrl,
    onClose 
}) => {
  return (
    <div className='fixed bottom-0 top-0 right-0 left-0 flex justify-center items-center customMax-800:mt-11'>

      <div className='bg-white showdow-lg rounded max-w5xl mx-auto p-4 mt-5'>

        <div className='w-fit ml-auto text-2xl hover:text-orange-600 cursor-pointer' onClick={onClose}>
            <IoIosCloseCircleOutline />
        </div>

        <div className=' flex justify-center p-4 py-2 max-w-[80vh] h-[80vh] '>
          <img src={imgUrl} alt='image url' className='w-full h-full object-contain '/> 
        </div>
      </div>
    </div>
   
  )
}

export default DisplayImage