import React, { useEffect, useState } from "react";
import summaryApi from "../../common";
import { toast } from "react-toastify";

const FAQ = () => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const response = await fetch(summaryApi.getFaqs.url, {
          method: summaryApi.getFaqs.method,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const responseData = await response.json();

        if (responseData.success) {
          setFaqs(responseData.data);
        } else {
          toast.error(responseData.message);
        }
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };
    fetchFAQs();
  }, []);

  return (
    <div className="bg-gradient-to-r from-blue-600 to-purple-800 p-6 min-h-screen">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-semibold text-center text-gray-900 mb-6">
          Frequently Asked Questions
        </h1>
        
        {faqs.length > 0 ? (
          faqs.map((faq) => (
            <div key={faq._id} className="mb-6 p-4 bg-gray-50 rounded-lg shadow-md hover:shadow-xl transition duration-300">
              <h2 className="text-2xl font-semibold text-blue-600">{faq.question}</h2>
              <p className="text-gray-700 mt-2">{faq.answer}</p>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-700 py-8">
            <p>No FAQs available at the moment.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FAQ;
