

// import React, { useState, useEffect } from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
// import summaryApi from '../common';
// import { useSelector } from 'react-redux';
// import ROLE from '../common/role';

// // Register Chart.js components
// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const Transactions = () => {
//   const [summaryData, setSummaryData] = useState([]);
//   const [filter, setFilter] = useState('weekly');
//   const [chartData, setChartData] = useState(null);
//   const [purchaseIds, setPurchaseIds] = useState({});
//   const [releaseStatus, setReleaseStatus] = useState({});
  
//   const user = useSelector((state) => state?.user?.user);
//   const userRole = user?.role;

//   // Fetch transaction summary
//   const fetchData = async () => {
//     const url =
//       userRole === ROLE.ADMIN
//         ? summaryApi.adminTransactionSummary.url
//         : userRole === ROLE.BUSINESSOWNER
//         ? summaryApi.sellerTransactionSummary.url
//         : null;

//     if (!url) {
//       console.error('Invalid user role or URL.');
//       return;
//     }

//     try {
//       const response = await fetch(`${url}?period=${filter}`, {
//         method: 'GET',
//         credentials: 'include',
//         headers: { 'Content-Type': 'application/json' },
//       });

//       if (!response.ok) throw new Error('Failed to fetch transaction summary');

//       const data = await response.json();
//       setSummaryData(data.data);

//       // Transform data for chart
//       const TransactionID = data.data.map((d) => d._id);
//       const amounts = data.data.map((d) => d.amount);

//       setChartData({
//         labels: TransactionID,
//         datasets: [
//           {
//             label: 'Total Revenue',
//             data: amounts,
//             backgroundColor: 'rgba(75,192,192,0.4)',
//             borderColor: 'rgba(75,192,192,1)',
//             borderWidth: 2,
//           },
//         ],
//       });
//     } catch (err) {
//       console.error('Error fetching data:', err);
//     }
//   };

//   // Fetch transactions on component mount and when filter/userRole changes
//   useEffect(() => {
//     fetchData();
//   }, [filter, userRole]);

//   // Handle fund release
//   const releaseFunds = async (purchaseId) => {
//     if (!purchaseId) {
//       alert('Please enter a valid transaction ID.');
//       return;
//     }

//     try {
//       const response = await fetch(summaryApi.fundReleaseUpdate.url, {
//         method: 'POST',
//         credentials: 'include',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ purchaseId }),
//       });

//       const result = await response.json();
//       setReleaseStatus((prevStatus) => ({
//         ...prevStatus,
//         [purchaseId]: result.message,
//       }));
//       alert(result.message);

//       if (result.success) {
//         // Refresh transaction summary after successful release
//         fetchData();
//       }
//     } catch (err) {
//       setReleaseStatus((prevStatus) => ({
//         ...prevStatus,
//         [purchaseId]: 'Error releasing funds',
//       }));
//       console.error('Error releasing funds:', err);
//     }
//   };

//   // Handle input change for specific purchase ID
//   const handlePurchaseIdChange = (purchaseId, value) => {
//     setPurchaseIds((prev) => ({
//       ...prev,
//       [purchaseId]: value,
//     }));
//   };

//   return (
//     <div className="min-h-screen bg-gray-100">
//       {/* Header */}
//       <div className="w-full bg-white shadow-md py-4 px-6">
//         <h1 className="text-2xl font-bold">
//           {userRole === ROLE.ADMIN ? 'Admin Dashboard' : 'Seller Dashboard'}
//         </h1>
//       </div>

//       {/* Main Content */}
//       <div className="container mx-auto py-6 px-4 md:px-8">
//         {/* Filters and Chart Section */}
//         <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
//           <div className="col-span-1">
//             <label className="block font-semibold text-gray-600 mb-2">
//               Filter by:
//             </label>
//             <select
//               className="w-full border rounded-lg px-4 py-2"
//               value={filter}
//               onChange={(e) => setFilter(e.target.value)}
//             >
//               <option value="weekly">Weekly</option>
//               <option value="monthly">Monthly</option>
//               <option value="yearly">Yearly</option>
//             </select>
//           </div>

//           <div className="col-span-1 md:col-span-2 bg-white rounded-lg shadow p-4">
//             {chartData ? (
//               <Line data={chartData} />
//             ) : (
//               <p className="text-gray-600 text-center">Loading chart...</p>
//             )}
//           </div>
//         </div>

//         {/* Summary Section */}
//         <div className="mt-8 bg-white rounded-lg shadow p-6">
//           <h2 className="text-xl font-semibold mb-4">Transaction Summary</h2>
//           <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
//             {summaryData.map((item) => (
//               <div
//                 key={item._id}
//                 className="border rounded-lg p-4 bg-gray-50 shadow-sm"
//               >
//                 <p className="font-medium">Transaction ID: {item._id}</p>
//                 <p>Total Amount: ${item.amount}</p>
//                 <p>Net Earnings: ${item.netAmount}</p>
//                 <p>Payment Status: {item.paymentStatus}</p>
//                 <p>Fund Status: {item.releaseStatus}</p>
//                 <p>Delivery Status: {item.deliveryStatus}</p>

//                 {userRole === ROLE.ADMIN && item.releaseStatus === 'pending' && (
//                   <div className="mt-4">
//                     <input
//                       type="text"
//                       className="w-full border rounded-lg px-3 py-2 mb-2"
//                       value={purchaseIds[item._id] || ''}
//                       onChange={(e) =>
//                         handlePurchaseIdChange(item._id, e.target.value)
//                       }
//                       placeholder="Enter Transaction ID"
//                     />
//                     <button
//                       onClick={() => releaseFunds(item._id)}
//                       className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
//                     >
//                       Release Fund
//                     </button>
//                     {releaseStatus[item._id] && (
//                       <p className="text-sm text-green-600 mt-2">
//                         {releaseStatus[item._id]}
//                       </p>
//                     )}
//                   </div>
//                 )}
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Transactions;


import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import summaryApi from '../../common';
import { useSelector } from 'react-redux';
import ROLE from '../../common/role';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Transactions = () => {
  const [summaryData, setSummaryData] = useState([]);
  const [filter, setFilter] = useState('weekly');
  const [days, setDays] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [releaseStatusFilter, setReleaseStatusFilter] = useState('');
  const [deliveryStatus, setDeliveryStatus] = useState('');
  const [chartData, setChartData] = useState(null);
  const [purchaseIds, setPurchaseIds] = useState({});
  const [releaseStatus, setReleaseStatus] = useState({});

  const user = useSelector((state) => state?.user?.user);
  const userRole = user?.role;

  // Fetch transaction summary
  const fetchData = async () => {
    const url =
      userRole === ROLE.ADMIN
        ? summaryApi.adminTransactionSummary.url
        : userRole === ROLE.BUSINESSOWNER
        ? summaryApi.sellerTransactionSummary.url
        : null;

    if (!url) {
      console.error('Invalid user role or URL.');
      return;
    }

    try {
      const queryParams = new URLSearchParams({
        period: filter,
        ...(days && { days }),
        ...(paymentStatus && { paymentStatus }),
        ...(releaseStatusFilter && { releaseStatus: releaseStatusFilter }),
        ...(deliveryStatus && { deliveryStatus }),
      });

      const response = await fetch(`${url}?${queryParams.toString()}`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) throw new Error('Failed to fetch transaction summary');

      const data = await response.json();
      setSummaryData(data.data);

      // Transform data for chart
      const TransactionID = data.data.map((d) => d._id);
      const amounts = data.data.map((d) => d.amount);

      setChartData({
        labels: TransactionID,
        datasets: [
          {
            label: 'Total Revenue',
            data: amounts,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderWidth: 2,
          },
        ],
      });
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter, days, paymentStatus, releaseStatusFilter, deliveryStatus]);

  // Handle fund release
  const releaseFunds = async (purchaseId) => {
    if (!purchaseId) {
      alert('Please enter a valid transaction ID.');
      return;
    }

    try {
      const response = await fetch(summaryApi.fundReleaseUpdate.url, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ purchaseId }),
      });

      const result = await response.json();
      setReleaseStatus((prevStatus) => ({
        ...prevStatus,
        [purchaseId]: result.message,
      }));
      alert(result.message);

      if (result.success) {
        // Refresh transaction summary after successful release
        fetchData();
      }
    } catch (err) {
      setReleaseStatus((prevStatus) => ({
        ...prevStatus,
        [purchaseId]: 'Error releasing funds',
      }));
      console.error('Error releasing funds:', err);
    }
  };

  const handlePurchaseIdChange = (purchaseId, value) => {
    setPurchaseIds((prev) => ({
      ...prev,
      [purchaseId]: value,
    }));
  };

  return (
    <div className="min-h-screen bg-gray-100 h-[100vh] overflow-auto">
      {/* Header */}
      <div className="w-full bg-white shadow-md py-4 px-6">
        <h1 className="text-2xl font-bold">
          {userRole === ROLE.ADMIN ? 'Admin Dashboard' : 'Seller Dashboard'}
        </h1>
      </div>

      {/* Main Content */}
      <div className="container mx-auto py-6 px-4 md:px-8">
        {/* Filters */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          <div>
            <label className="block font-semibold text-gray-600 mb-2">
              Filter by Period:
            </label>
            <select
              className="w-full border rounded-lg px-4 py-2"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
            </select>
          </div>

          <div>
            <label className="block font-semibold text-gray-600 mb-2">
              Filter by Days:
            </label>
            <input
              type="number"
              className="w-full border rounded-lg px-4 py-2"
              placeholder="Enter Days"
              value={days}
              onChange={(e) => setDays(e.target.value)}
            />
          </div>

          <div>
            <label className="block font-semibold text-gray-600 mb-2">
              Payment Status:
            </label>
            <select
              className="w-full border rounded-lg px-4 py-2"
              value={paymentStatus}
              onChange={(e) => setPaymentStatus(e.target.value)}
            >
              <option value="">All</option>
              <option value="completed">Completed</option>
              <option value="pending">Pending</option>
            </select>
          </div>

          <div>
            <label className="block font-semibold text-gray-600 mb-2">
              Release Status:
            </label>
            <select
              className="w-full border rounded-lg px-4 py-2"
              value={releaseStatusFilter}
              onChange={(e) => setReleaseStatusFilter(e.target.value)}
            >
              <option value="">All</option>
              <option value="released">Released</option>
              <option value="pending">Pending</option>
            </select>
          </div>

          <div>
            <label className="block font-semibold text-gray-600 mb-2">
              Delivery Status:
            </label>
            <select
              className="w-full border rounded-lg px-4 py-2"
              value={deliveryStatus}
              onChange={(e) => setDeliveryStatus(e.target.value)}
            >
              <option value="">All</option>
              <option value="delivered">Delivered</option>
              <option value="pending">Pending</option>
            </select>
          </div>
        </div>

        {/* Chart Section */}
        <div className="mt-6 bg-white rounded-lg shadow p-6">
          {chartData ? (
            <Line data={chartData} />
          ) : (
            <p className="text-gray-600 text-center">Loading chart...</p>
          )}
        </div>

        {/* Transactions Section */}
        <div className="mt-8 bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Transaction Summary</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {summaryData.map((item) => (
              <div
                key={item._id}
                className="border rounded-lg p-4 bg-gray-50 shadow-sm"
              >
                <p className="font-medium">Transaction ID: {item._id}</p>
                <p>Total Amount: ${item.amount}</p>
                <p>Net Earnings: ${item.netAmount}</p>
                <p>Payment Status: {item.paymentStatus}</p>
                <p>Fund Status: {item.releaseStatus}</p>
                <p>Delivery Status: {item.deliveryStatus}</p>

                {userRole === ROLE.ADMIN && item.releaseStatus === 'pending' && (
                  <div className="mt-4">
                    <input
                      type="text"
                      className="w-full border rounded-lg px-3 py-2 mb-2"
                      value={purchaseIds[item._id] || ''}
                      onChange={(e) =>
                        handlePurchaseIdChange(item._id, e.target.value)
                      }
                      placeholder="Enter Transaction ID"
                    />
                    <button
                      onClick={() => releaseFunds(item._id)}
                      className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                    >
                      Release Fund
                    </button>
                    {releaseStatus[item._id] && (
                      <p className="text-sm text-green-600 mt-2">
                        {releaseStatus[item._id]}
                      </p>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;



