// import React, { useEffect, useState } from 'react';
// import summaryApi from '../../common';


// const ShipmentInfo = () => {
//   const [shippingRates, setShippingRates] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchShippingRates = async () => {
//       setLoading(true);
//       try {
//         const response = await fetch(summaryApi.getShipmentRate.url, {
//           method: summaryApi.getShipmentRate.method,
//           credentials: 'include',
//           headers: {
//             'content-type': 'application/json',
//           },
//         });
  
//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }
  
//         const responseData = await response.json();
//         console.log('Fetched shipping rates:', responseData);
  
//         // Ensure responseData.data is an array
//         setShippingRates(Array.isArray(responseData.data) ? responseData.data : []);
//       } catch (error) {
//         console.error('Failed to fetch shipping rates:', error);
//         setShippingRates([]); // Fallback to an empty array
//       } finally {
//         setLoading(false);
//       }
//     };
  
//     fetchShippingRates();
//   }, []);

//   const handleDelete = async (id) => {
//     if (window.confirm('Are you sure you want to delete this shipment?')) {
//       try {
//         const response = await fetch(`${summaryApi.deleteshipment.url}${id}`, {
//           method: 'DELETE',
//           credentials: 'include',
//           headers: {
//             'content-type': 'application/json',
//           },
//         });

//         const result = await response.json();
//         if (result.success) {
//           toast.success('Shipment deleted successfully');
//           setShippingRates(shippingRates.filter((rate) => rate._id !== id));
//         } else {
//           toast.error(result.message);
//         }
//       } catch (error) {
//         toast.error('Failed to delete shipment');
//       }
//     }
//   };
  
  
//   if (loading) return <p>Loading shipment information...</p>;
//   if (!Array.isArray(shippingRates) || shippingRates.length === 0) {
//     return <p>No shipment information available.</p>;
//   }

//   return (
//     <div className="container mx-auto p-6">
//       <h1 className="text-2xl font-bold mb-4">Shipping Information</h1>
//       <div className="grid grid-cols-1 gap-4">
//         {shippingRates.map((rate) => (
//           <div
//             key={rate.id}
//             className="border rounded-lg p-4 shadow hover:shadow-lg transition-all"
//           >
//             <p>
//               <strong>From:</strong> {rate.origin}
//             </p>
//             <p>
//               <strong>To:</strong> {rate.destination}
//             </p>
//             <p>
//               <strong>Method:</strong> {rate.method}
//             </p>
//             <p>
//               <strong>Weight:</strong> {rate.weight}
//             </p>
//             <p>
//               <strong>Dimensions:</strong> {rate.dimensions || 'N/A'}
//             </p>
//             <p>
//               <strong>Cost:</strong> {rate.cost}
//             </p>
//             <p>
//               <strong>Duration:</strong> {rate.duration}
//             </p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ShipmentInfo;

import React, { useEffect, useState } from 'react';
import summaryApi from '../../common';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import ROLE from '../../common/role';

const ShipmentInfo = () => {
  const user = useSelector(state => state?.user?.user);
  const [shippingRates, setShippingRates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingShipment, setEditingShipment] = useState(null); // Store the shipment being edited
  const [formData, setFormData] = useState({
    origin: '',
    destination: '',
    method: '',
    weight: '',
    dimensions: '',
    duration: '',
    cost: '',
  });

  useEffect(() => {
    const fetchShippingRates = async () => {
      setLoading(true);
      try {
        const response = await fetch(summaryApi.getShipmentRate.url, {
          method: summaryApi.getShipmentRate.method,
          credentials: 'include',
          headers: {
            'content-type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json(); 

        // Ensure responseData.data is an array
        setShippingRates(Array.isArray(responseData.data) ? responseData.data : []);
      } catch (error) {
        console.error('Failed to fetch shipping rates:', error);
        setShippingRates([]); // Fallback to an empty array
      } finally {
        setLoading(false);
      }
    };

    fetchShippingRates();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this shipment?')) {
      try {
        const response = await fetch(`${summaryApi.deleteShipment.url}/${id}`, {
          method: summaryApi.deleteShipment.method,
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        const result = await response.json();
        if (result.success) {
          toast.success('Shipment deleted successfully');
          setShippingRates(shippingRates.filter((rate) => rate._id !== id));
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error('Failed to delete shipment');
      }
    }
  };
  

  const handleEditClick = (rate) => {
    setEditingShipment(rate);
    setFormData({
      origin: rate.origin,
      destination: rate.destination,
      method: rate.method,
      weight: rate.weight,
      dimensions: rate.dimensions,
      duration: rate.duration,
      cost: rate.cost,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const updatedData = { ...formData };

    try {
      const response = await fetch(`${summaryApi.updateShipment.url}/${editingShipment._id}`, {
        method: summaryApi.updateShipment.method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });
  
      const result = await response.json();
      if (result.success) {
        toast.success('Shipment updated successfully');
        setShippingRates(shippingRates.map((rate) =>
          rate._id === editingShipment._id ? result.data : rate
        ));
        setEditingShipment(null);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error('Failed to update shipment');
    }
  };
  

  if (loading) return <p>Loading shipment information...</p>;
  if (!Array.isArray(shippingRates) || shippingRates.length === 0) {
    return <p>No shipment information available.</p>;
  }

  return (
    <div className="container mx-auto p-6 h-[100vh] overflow-auto ">
      <h1 className="text-2xl font-bold mb-4">Shipping Information</h1>

      {/* Edit Modal */}
      {editingShipment && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center overflow-auto mt-20">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4 mt-16">Edit Shipment</h2>
            <form onSubmit={handleEditSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Origin</label>
                <input
                  type="text"
                  name="origin"
                  value={formData.origin}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Destination</label>
                <input
                  type="text"
                  name="destination"
                  value={formData.destination}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Method</label>
                <input
                  type="text"
                  name="method"
                  value={formData.method}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Weight</label>
                <input
                  type="text"
                  name="weight"
                  value={formData.weight}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Dimensions</label>
                <input
                  type="text"
                  name="dimensions"
                  value={formData.dimensions}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Duration</label>
                <input
                  type="text"
                  name="duration"
                  value={formData.duration}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Cost</label>
                <input
                  type="text"
                  name="cost"
                  value={formData.cost}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded"
                />
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => setEditingShipment(null)}
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  Cancel
                </button>
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

    <div className=" flex flex-col">
      <div className="grid grid-cols-1 gap-4 flex-1">
        {shippingRates.map((rate) => (
          <div key={rate._id} className="border rounded-lg p-4 shadow hover:shadow-lg transition-all ">
            <p><strong>From:</strong> {rate.origin}</p>
            <p><strong>To:</strong> {rate.destination}</p>
            <p><strong>Method:</strong> {rate.method || 'N/A'}</p>
            <p><strong>Weight:</strong> {rate.weight}</p>
            <p><strong>Dimensions:</strong> {rate.dimensions || 'N/A'}</p>
            <p><strong>Cost:</strong> {rate.cost}</p>
            <p><strong>Duration:</strong> {rate.duration}</p>
            
            <div className="mt-4">
              {user?.role === ROLE.ADMIN && (
                <>
                  <button 
                    onClick={() => handleDelete(rate._id)} 
                    className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                  >
                    Delete
                  </button>
                  <button 
                    onClick={() => handleEditClick(rate)} 
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Edit
                  </button>
                </>
              )}
            </div>

          </div>
        ))}
      </div>
    </div>


    </div>
  );
};

export default ShipmentInfo;
