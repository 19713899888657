// import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

// const GuestCheckout = () => {
//     const [guestDetails, setGuestDetails] = useState({
//         name: '',
//         email: '',
//         address: '',
//     });

//     const user = useSelector((state) => state.user.user);
//     const navigate = useNavigate();

//     useEffect(() => {
//         if (user) {
//             navigate('/cart');
//         }
//     }, [user, navigate]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setGuestDetails((prevDetails) => ({
//             ...prevDetails,
//             [name]: value,
//         }));
//     };

//     const handleGuestCheckout = (e) => {
//         e.preventDefault();

//         if (!guestDetails.name || !guestDetails.email || !guestDetails.address) {
//             alert('Please fill in all the fields.');
//             return;
//         }

//         // Generate guest token and store details in localStorage
//         //const guestToken = `guest-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
//         const guestData = { ...guestDetails };
//         localStorage.setItem('guestDetails', JSON.stringify(guestData));

//         // Redirect to the cart
//         navigate('/cart');
//     };

//     const handleLogin = () => {
//         navigate('/login', { state: { redirect: '/cart' } });
//     };

//     return (
//         <div className="container mx-auto max-w-md p-6 mt-10 border rounded shadow-lg bg-white">
//             <h1 className="text-xl font-semibold text-center text-orange-600 mb-4">Guest Checkout</h1>
//             <p className="text-slate-700 text-center mb-6">
//                 You can log in to your account or continue as a guest to complete your purchase.
//             </p>

//             <div className="flex flex-col gap-4">
//                 <button
//                     className="bg-orange-600 hover:bg-orange-500 text-white font-semibold py-2 px-4 rounded"
//                     onClick={handleLogin}
//                 >
//                     Log In
//                 </button>

//                 <form onSubmit={handleGuestCheckout}>
//                     <input
//                         type="text"
//                         name="name"
//                         placeholder="Full Name"
//                         className="w-full mb-4 px-3 py-2 border rounded"
//                         value={guestDetails.name}
//                         onChange={handleChange}
//                         required
//                     />
//                     <input
//                         type="email"
//                         name="email"
//                         placeholder="Email"
//                         className="w-full mb-4 px-3 py-2 border rounded"
//                         value={guestDetails.email}
//                         onChange={handleChange}
//                         required
//                     />
//                     <input
//                         type="text"
//                         name="address"
//                         placeholder="Address"
//                         className="w-full mb-4 px-3 py-2 border rounded"
//                         value={guestDetails.address}
//                         onChange={handleChange}
//                         required
//                     />
//                     <button
//                         type="submit"
//                         className="bg-green-500 hover:bg-green-400 text-white font-semibold py-2 px-4 rounded"
//                     >
//                         Continue as Guest
//                     </button>
//                 </form>
//             </div>
//         </div>
//     );
// };

// export default GuestCheckout;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const GuestCheckout = () => {
    const [guestDetails, setGuestDetails] = useState({
        name: '',
        email: '',
        street: '',
        city: '',
        state: '',
        postalCode: '',
    });

    const [loadingZip, setLoadingZip] = useState(false);
    const user = useSelector((state) => state.user.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate('/cart');
        }
    }, [user, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setGuestDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));

        // Auto-fetch city and state when ZIP code changes
        if (name === 'postalCode' && value.length === 5) {
            fetchCityAndState(value);
        }
    };

    const fetchCityAndState = async (zip) => {
        setLoadingZip(true);
        try {
            const response = await fetch(`https://api.zippopotam.us/us/${zip}`);
            if (response.ok) {
                const data = await response.json(); // Extract JSON body
                const places = data.places;
                if (places && places.length > 0) {
                    const place = places[0];
                    setGuestDetails((prevDetails) => ({
                        ...prevDetails,
                        city: place['place name'],
                        state: place['state abbreviation'],
                    }));
                }
            } else {
                console.error('Failed to fetch address details:', response.statusText);
            }
            
        } catch (error) {
            console.error('Error fetching city and state:', error);
            alert('Invalid ZIP code. Please check and try again.');
        } finally {
            setLoadingZip(false);
        }
    };

    const validateAddress = () => {
        const { street, city, state, postalCode } = guestDetails;
        if (!street || !city || !state || !postalCode) {
            return 'All address fields are required.';
        }
    
        return null;
    };

    const handleGuestCheckout = (e) => {
        e.preventDefault();

        if (!guestDetails.name || !guestDetails.email) {
            alert('Please fill in all the fields.');
            return;
        }

        const addressError = validateAddress();
        if (addressError) {
            alert(addressError);
            return;
        }

        // Combine address properties into a single string
        const combinedAddress = `${guestDetails.street}, ${guestDetails.city}, ${guestDetails.state} ${guestDetails.postalCode}`;

        // Create guestData with the combined address
        const guestData = {
            name: guestDetails.name,
            email: guestDetails.email,
            address: combinedAddress,
        };

        // Store in localStorage and navigate to cart
        localStorage.setItem('guestDetails', JSON.stringify(guestData));
        navigate('/cart');
    };

    const handleLogin = () => {
        navigate('/login', { state: { redirect: '/cart' } });
    };

    return (
        <div className="container mx-auto max-w-md p-6 mt-10 border rounded shadow-lg bg-white">
            <h1 className="text-xl font-semibold text-center text-orange-600 mb-4">Guest Checkout</h1>
            <p className="text-slate-700 text-center mb-6">
                You can log in to your account or continue as a guest to complete your purchase.
            </p>

            <div className="flex flex-col gap-4">
                <button
                    className="bg-orange-600 hover:bg-orange-500 text-white font-semibold py-2 px-4 rounded"
                    onClick={handleLogin}
                >
                    Log In
                </button>

                <form onSubmit={handleGuestCheckout}>
                    <input
                        type="text"
                        name="name"
                        placeholder="Full Name"
                        className="w-full mb-4 px-3 py-2 border rounded"
                        value={guestDetails.name}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="w-full mb-4 px-3 py-2 border rounded"
                        value={guestDetails.email}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="text"
                        name="street"
                        placeholder="Street Address"
                        className="w-full mb-4 px-3 py-2 border rounded"
                        value={guestDetails.street}
                        onChange={handleChange}
                        required
                    />
                    <div className="flex gap-2">
                        <input
                            type="text"
                            name="city"
                            placeholder="City"
                            className="w-1/2 mb-4 px-3 py-2 border rounded"
                            value={guestDetails.city}
                            onChange={handleChange}
                            disabled={loadingZip}
                            required
                        />
                        <input
                            type="text"
                            name="state"
                            placeholder="State"
                            className="w-1/4 mb-4 px-3 py-2 border rounded"
                            value={guestDetails.state}
                            onChange={handleChange}
                            disabled={loadingZip}
                            required
                        />
                        <input
                            type="text"
                            name="postalCode"
                            placeholder="ZIP Code"
                            className="w-1/4 mb-4 px-3 py-2 border rounded"
                            value={guestDetails.postalCode}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className={`bg-green-500 hover:bg-green-400 text-white font-semibold py-2 px-4 rounded ${loadingZip ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loadingZip}
                    >
                        Continue as Guest
                    </button>
                </form>
            </div>
        </div>
    );
};

export default GuestCheckout;






