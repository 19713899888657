import React from "react";

const FreshProductPreservation = () => {
  return (
    <div className="max-w-4xl mx-auto px-6 py-12 font-sans">
      <h1 className="text-center text-3xl font-bold mb-6">Fresh Product and Food Preservation</h1>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">Why Preservation Matters</h2>
        <p>
          Proper preservation of fresh products and food ensures they maintain
          their quality, taste, and nutritional value. It also reduces waste and
          extends shelf life, making it possible to enjoy fresh items longer.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">Preservation Methods for Fresh Products</h2>
        <p>
          Depending on the type of product, we use the following preservation
          techniques:
        </p>
        <ul className="list-disc list-inside mt-4">
          <li>
            <strong>Refrigeration:</strong> Keeps products at low temperatures to
            slow down bacterial growth and spoilage.
          </li>
          <li>
            <strong>Vacuum Sealing:</strong> Removes air from packaging to
            preserve freshness and prevent oxidation.
          </li>
          <li>
            <strong>Hydration Control:</strong> Ensures leafy greens and other
            produce retain moisture with damp paper towels or special bags.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">Food Preservation Techniques</h2>
        <p>
          For food items, we follow industry-best preservation practices:
        </p>
        <ul className="list-disc list-inside mt-4">
          <li>
            <strong>Freezing:</strong> Ideal for perishable items like meat,
            seafood, and prepared meals to extend their storage time.
          </li>
          <li>
            <strong>Pasteurization:</strong> Used for dairy and juices to kill
            harmful bacteria while preserving taste.
          </li>
          <li>
            <strong>Packaging:</strong> Airtight containers and food-safe
            materials protect against contamination and spoilage.
          </li>
          <li>
            <strong>Desiccation:</strong> Removing moisture from foods such as
            herbs and spices to preserve them for long periods.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">Tips for Home Preservation</h2>
        <p>
          You can keep your fresh products and food safe and delicious by:
        </p>
        <ul className="list-disc list-inside mt-4">
          <li>Storing produce in the appropriate temperature zones of your fridge.</li>
          <li>
            Using airtight containers or resealable bags to maintain freshness.
          </li>
          <li>
            Labeling and dating stored items to keep track of their shelf life.
          </li>
        </ul>
      </section>

      <section className="mb-8 text-center">
        <p className="mb-4">
          To learn more about fresh product and food preservation, check out our
          educational video.
        </p>
        <button
          className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
          onClick={() => window.open('https://www.youtube.com/watch?v=aiF0APhp544', '_blank')}
        >
          Watch on YouTube
        </button>
      </section>
    </div>
  );
};

export default FreshProductPreservation;
