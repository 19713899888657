
export const initialState = {
  data: [],
  loading: false,
  page: 1,
  hasMore: true,
  filterCategoryList: [],
  sortBy: '',
  selectCategory: {},
  sidebarVisible: false,
};

export const actionTypes = {
  SET_LOADING: 'SET_LOADING',
  SET_DATA: 'SET_DATA',
  SET_PAGE: 'SET_PAGE',
  SET_HAS_MORE: 'SET_HAS_MORE',
  SET_FILTER_CATEGORY: 'SET_FILTER_CATEGORY',
  SET_SORT_BY: 'SET_SORT_BY',
  SET_SELECT_CATEGORY: 'SET_SELECT_CATEGORY',
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  RESET_DATA: 'RESET_DATA',
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    case actionTypes.SET_DATA:
      return {
        ...state,
        data: action.payload.page === 1 ? action.payload.data : [...state.data, ...action.payload.data],
      };
    case actionTypes.SET_PAGE:
      return { ...state, page: action.payload };
    case actionTypes.SET_HAS_MORE:
      return { ...state, hasMore: action.payload };
    case actionTypes.SET_FILTER_CATEGORY:
      return { ...state, filterCategoryList: action.payload };
    case actionTypes.SET_SORT_BY:
      return { ...state, sortBy: action.payload };
    case actionTypes.SET_SELECT_CATEGORY:
      return { ...state, selectCategory: action.payload };
    case actionTypes.TOGGLE_SIDEBAR:
      return { ...state, sidebarVisible: !state.sidebarVisible };
    case actionTypes.RESET_DATA:
      return { ...state, data: [], page: 1, hasMore: true };
    default:
      return state;
  }
};

