import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice'
import freelancerReducer from './freelancerSlice'


export const store = configureStore({
  reducer: {
    user : userReducer,
    freelancer : freelancerReducer, 
  
  },
})