

import React from 'react';
import backgroundVideo from '../assest/backgroundVideo.mp4'

const SellerLandingPage = () => {
  return (
    <div className="bg-gray-100 text-gray-900 ">
      {/* Navbar */}
      <nav className="bg-orange-700 p-4 py-2 shadow-md flex justify-between items-center customMax-800:mt-10">
        <h1 className="text-white border rounded py-1.5 px-3 text-lg font-bold">Sale with Us</h1>
        <a href="/signup" className="bg-white text-orange-500 px-4 py-2 rounded-lg shadow-md font-semibold hover:bg-orange-100">Sign Up</a>
      </nav>

      {/* Hero Section with Background Video */}
      <section className="relative h-[70vh] w-full">
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-[70vh] object-cover"
        >
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="relative z-10 bg-black bg-opacity-50 h-[70vh] flex items-center justify-center text-center p-6">
          <div className="bg-white bg-opacity-90 p-8 rounded-lg shadow-lg pb-11 ">
            <h2 className="text-3xl font-bold mb-4">Expand Your Business Internationally</h2>
            <p className="text-lg mb-6">Reach millions of fellow country men and women globally. Sell local products, grow your business, and enjoy fast payments.</p>
            <a href="/signup" className="bg-orange-700 text-white px-6 py-3 rounded-lg font-semibold hover:bg-orange-800">Join for FREE</a>
          </div>
        </div>
      </section>

      {/* Why Sell with Us */}
      <section className="py-12 px-6 bg-white -mt-6">
        <h3 className="text-2xl font-bold text-center mb-6">Why Sell on Our Platform?</h3>
        <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex items-start space-x-4 border rounded-lg p-3">
            <div className="text-orange-700 text-3xl">&#10003;</div>
            <p>Access over 20million Indians and 10million Nigerians in the UK and USA, plus millions more globally.</p>
          </div>
          <div className="flex items-start space-x-4 border rounded-lg p-3">
            <div className="text-orange-700 text-3xl">&#10003;</div>
            <p>Automated seller dashboard to track sales, dispatch updates, and payments.</p>
          </div>
          <div className="flex items-start space-x-4 border rounded-lg p-3">
            <div className="text-orange-700 text-3xl">&#10003;</div>
            <p>Year-long marketing campaigns to attract buyers to your products.</p>
          </div>
          <div className="flex items-start space-x-4 border rounded-lg p-3">
            <div className="text-orange-700 text-3xl">&#10003;</div>
            <p>Fast payment processing—funds released within 24 hours of delivery confirmation.</p>
          </div>
          <div className="flex items-start space-x-4 border rounded-lg p-3">
            <div className="text-orange-700 text-3xl">&#10003;</div>
            <p>Global shipping via trusted logistics partners like DHL and FedEx.</p>
          </div>
          <div className="flex items-start space-x-4 border rounded-lg p-3">
            <div className="text-orange-700 text-3xl">&#10003;</div>
            <p>No hidden charges, no signUp fee, just REGISTER and start selling </p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section id="signup" className="py-16 bg-orange-700 text-white text-center">
        <h3 className="text-2xl font-bold mb-6">Ready to Start Selling?</h3>
        <p className="text-lg mb-8">Join a platform designed to grow your business globally for FREE. Start earning weekly and monthly.</p>
        <a href="/signup" className="bg-white text-orange-700 px-6 py-3 rounded-lg font-semibold hover:bg-orange-100">Sign Up Now</a>
      </section>
    </div>
  );
};

export default SellerLandingPage;
