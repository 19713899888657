
import React, { useContext, useState } from 'react';
import loginIcon from '../assest/signin.gif';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { FaEyeSlash } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import summaryApi from '../common';
import Context from '../context';
import { GoogleLogin } from '@react-oauth/google';
import PrivacyPolicyDialog from '../components/PrivacyPolicyDialog';
import LoadingIcons from 'react-loading-icons'
import UpdateGoogleUser from '../components/UpdateGoogleUser';

function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [data, setData] = useState({ email: '', password: '' });
    const [showUpdateGoogleUser, setShowUpdateGoogleUser] = useState(false);
    const [newUserId, setNewUserId] = useState(null); // Track new Google user ID
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();
    const location = useLocation();
    const { fetchUserdetails, fetchUserAddToCartCount } = useContext(Context);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const dataResponse = await fetch(summaryApi.signIn.url, {
                method: summaryApi.signIn.method,
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });

            if (!dataResponse.ok) {
              console.log('loading state:', loading)
              if (dataResponse.status >= 500) {
                  navigate("/error-fallback");
              } else {
                  const errorData = await dataResponse.json();
                  toast.error(errorData.message || "Error during login");
                  setLoading(false);
              }
              return;
            }

            const dataApi = await dataResponse.json();
            if (dataApi.redirectToVerify){
                localStorage.setItem("verificationToken", dataApi.token);
                toast.success(dataApi.message)
                navigate("/verify-otp");
            } else if (dataApi.success) {
                toast.success(dataApi.message);
                await fetchUserdetails();
                await fetchUserAddToCartCount();

                 // Check for a redirect path from state
                const redirectPath = location.state?.redirect
                if (redirectPath){
                  navigate(redirectPath);
                } else  if (dataApi.user.role === "Seller" || dataApi.user.role === "ADMIN") {
                  navigate("/dashboard/all-products");
                } else {navigate('/')}     
            }
        } catch (error) {
            console.error("Error logging in:", error);
            navigate('/error-fallback');
            setLoading(false)
        } 
    };

    // Google login handler
    const handleLoginSuccess = (response) => {
        const googleToken = response.credential;
        loginWithGoogle(googleToken);
    };

    const loginWithGoogle = async (googleToken) => {
        try {
            const response = await fetch(summaryApi.googleLogin.url, {
                method: summaryApi.googleLogin.method,
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token: googleToken }),
            });
    
           
            const data = await response.json(); // Parse JSON once
    
            // Check for 400 status to display error message
            if (response.status === 400) {
                toast.error(data.message || "An error occurred during Google Sign-In.");
                return; // Stop execution here if there's an error
            }
    
            // Handle success case if the response is OK
            if (response.ok && data.success) {
              setNewUserId(data.user._id);
            
              // Check if the user has no role
              if (!data.user.role) {
                setShowUpdateGoogleUser(true); // Show role selection for new users
              }
            
              // Additional check: If the user is a 'Seller' and lacks a mobile number
              if (data.user.role === "Seller" && !data.user.mobileNumber) {
                setShowUpdateGoogleUser(true);
              }
            
              await fetchUserdetails();
              await fetchUserAddToCartCount();
            
              const redirectPath = location.state?.redirect;
            
              // Handle navigation
              if (redirectPath) {
                navigate(redirectPath);
              } else if (data.user.role === "Seller" || data.user.role === "ADMIN") {
                navigate("/dashboard/all-products");
              } else {
                navigate("/");
              }

            }else {
                // Handle unexpected cases if status isn't 400 but also not OK
                console.log("Unexpected response status or success flag:", response.status, data.success);
            }
    
        } catch (error) {
            console.error("Google Sign-In error:", error);
        }
    };
    

    // Handle role selected in updateGoogleLogin
    const handleUpdateGoogleUser = async () => {
        setShowUpdateGoogleUser(false);
        await fetchUserdetails();
        await fetchUserAddToCartCount();
        
        const redirectPath = location.state?.redirect;
            
        // Handle navigation
        if (redirectPath) {
          navigate(redirectPath);
        } else if (data.user.role === "Seller" || data.user.role === "ADMIN") {
          navigate("/dashboard/all-products");
        } else {
          navigate("/");
        }

    };

    const handleOpenPolicy = () => setShowPrivacyPolicy(true);
    const handleClosePolicy = () => setShowPrivacyPolicy(false);

    return (
        <section id='login'>
          <div className='mx-auto mt-5 container customMin-300:max-w-full p-4'>
            <div 
              className='p-5 w-full bg-white max-w-sm mx-auto customMax-800:mt-5'
              style={{
                opacity: showUpdateGoogleUser ? 0.1 : 1, 
                transition: 'opacity 0.3s ease',
                pointerEvents: showUpdateGoogleUser ? 'none' : 'auto', // Disable interaction with the form when role selection is shown
              }}
            >
              <div className='w-20 mx-auto h-20'>
                <img src={loginIcon} alt='loginIcon'/>
              </div>
      
              <form className='pt-6 flex flex-col gap-2' onSubmit={handleSubmit}>
                <div>
                  <label>Email:</label>
                  <div className='bg-slate-100 p-2'>
                    <input 
                      type='email' 
                      placeholder='enter email' 
                      className='w-full h-full bg-transparent outline-none'
                      name='email'
                      value={data.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
      
                <div>
                  <label>Password:</label>
                  <div className='bg-slate-100 p-2 flex'>
                    <input 
                      type={showPassword ? "text" : "password"} 
                      placeholder='enter password' 
                      name='password'
                      value={data.password}
                      onChange={handleChange}
                      className='w-full h-full bg-transparent outline-none'
                    />
                    <div className='cursor-pointer text-xl' onClick={() => setShowPassword(prev => !prev)}>
                      {showPassword ? <FaEyeSlash /> : <MdOutlineRemoveRedEye />}
                    </div>
                  </div>  
                </div>  
      
                <div className='hover:underline hover:text-orange-700 cursor-pointer ml-auto'>
                  <Link to={'/forgot-password'}>forgotten password?</Link>
                </div>

                <div
                  className='bg-orange-500 hover:bg-orange-700 text-white cursor-pointer mt-7 px-20 py-2 mx-auto rounded w-full text-center'
                  onClick={(e) => {
                    // Trigger form submission programmatically
                    e.currentTarget.closest('form')?.requestSubmit();
                  }}
                >
                  {loading ? (
                    <LoadingIcons.Bars className="text-orange-600 h-[1.33em]" />
                  ) : (
                    'Login'
                  )}
                </div>

      
                {/* <div className='bg-orange-500 hover:bg-orange-700 text-white cursor-pointer mt-7 px-20 py-2 mx-auto rounded'>
                  <button type="submit" disabled={loading}>
                        {
                            loading ? (<LoadingIcons.Bars className="text-orange-600 h-[1.33em]"/>) : ('Login')
                        }
                  </button>
                </div> */}
      
                {/* Google Sign-in Button */}
                <GoogleLogin
                  onSuccess={handleLoginSuccess}
                  onError={() => console.log('Login Failed')}
                />
              </form>
              <p className='my-4 hover:underline hover:text-orange-700 cursor-pointer'> 
                <Link to={"/signup"}>Don't have an account? Sign up</Link>
              </p>

              <button onClick={handleOpenPolicy} className='hover:underline hover:text-orange-600'>Privacy Policy</button>

              {/* Privacy Policy Dialog */}
              <PrivacyPolicyDialog open={showPrivacyPolicy} onClose={handleClosePolicy} />

            </div>
          </div>
      
          {/* Role Selection Dialog */}
          {showUpdateGoogleUser && (
            <UpdateGoogleUser userId={newUserId} updateSelected={handleUpdateGoogleUser } />
          )}
        </section>
      );
      

}

export default Login;
   // return (
    //     <section id="login">
    //         <div className="mx-auto mt-5 container p-4">
    //             <div className="p-5 w-full bg-white max-w-sm mx-auto">
    //                 <div className="w-20 mx-auto h-20">
    //                     <img src={loginIcon} alt="loginIcon" />
    //                 </div>

    //                 <form className="pt-6 flex flex-col gap-2" onSubmit={handleSubmit}>
    //                     <label>Email :</label>
    //                     <input type="email" name="email" value={data.email} onChange={handleChange} />

    //                     <label>Password :</label>
    //                     <input type={showPassword ? "text" : "password"} name="password" value={data.password} onChange={handleChange} />

    //                     <button type="submit">Login</button>

    //                     <GoogleLogin onSuccess={handleLoginSuccess} onError={() => console.log('Login Failed')} />
    //                 </form>

    //                 <Link to="/signup">Don't have account? Sign up</Link>

    //                 {showUpdateGoogleUser && (
    //                     <ChooseUserRole userId={newUserId} onRoleSelected={handleRoleSelected} />
    //                 )}
    //             </div>
    //         </div>
    //     </section>
    // );
