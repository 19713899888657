// import React, { useContext, useEffect, useRef, useState } from 'react';
// import fetchProductsByCategory from '../helpers/FetchProductsByCategory';
// import displayPrice from '../helpers/FormatPrice';
// import { Link } from 'react-router-dom';
// import addToCart from '../helpers/AddToCart';
// import Context from '../context';
// import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

// const VerticalCardProduct = ({ localMarket, category, heading, className = '' }) => {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [page, setPage] = useState(1);
//     const [hasMore, setHasMore] = useState(true);
//     const scrollElement = useRef();

//     const { fetchUserAddToCartCount } = useContext(Context);

//     const handleAddCart = async (e, id) => {
//         await addToCart(e, id);
//         fetchUserAddToCartCount();
//     };

//     // Fetch products by category with pagination
//     const fetchData = async () => {
//         if (loading || !hasMore) return;

//         setLoading(true);
//         const categoryProduct = await fetchProductsByCategory(localMarket, category, page, 10); // Limit to 10 items per call
//         setLoading(false);

//         if (categoryProduct && categoryProduct.data.length > 0) {
//             setData((prevData) => [...prevData, ...categoryProduct.data]);
//             if (categoryProduct.data.length < 10) {
//                 setHasMore(false); // No more products if returned items are fewer than 10
//             }
//         } else {
//             setHasMore(false);
//         }
//     };

//     useEffect(() => {
//         setData([]);
//         setPage(1);
//         setHasMore(true);
//         fetchData();
//     }, [localMarket]);

//     useEffect(() => {
//         if (page > 1) {
//             fetchData();
//         }
//     }, [page]);

//     const handleScroll = () => {
//         if (!scrollElement.current) return;

//         const { scrollTop, scrollHeight, clientHeight } = scrollElement.current;
//         if (scrollTop + clientHeight >= scrollHeight * 0.5 && hasMore) {
//             setPage((prevPage) => prevPage + 1); // Increment page when reaching 50% of the scroll height
//         }
//     };

//     useEffect(() => {
//         if (scrollElement.current) {
//             scrollElement.current.addEventListener('scroll', handleScroll);
//         }
//         return () => {
//             if (scrollElement.current) {
//                 scrollElement.current.removeEventListener('scroll', handleScroll);
//             }
//         };
//     }, [hasMore]);

//     return (
//         <div className='container mx-auto px-4 my-6 relative'>
//             <div className={`customMax-800 ${className}`}>
//                 <h2 className='text-2xl font-semibold py-4'>{heading}</h2>

//                 {loading && data.length === 0 ? (
//                     <div className="grid customMax-639:grid-cols-2 customMax-639:grid-rows-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 overflow-x-auto customMax-639:overflow-y-scroll customMax-639:max-h-[600px]">
//                         {Array(6).fill(null).map((_, index) => (
//                             <div key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow animate-pulse'>
//                                 <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
//                                     <div className='bg-gray-300 w-full h-full'></div>
//                                 </div>
//                                 <div className='p-4 grid gap-3'>
//                                     <div className='bg-gray-300 h-6 w-3/4 rounded'></div>
//                                     <div className='bg-gray-300 h-4 w-1/2 rounded'></div>
//                                     <div className='bg-gray-300 h-5 w-1/3 rounded'></div>
//                                     <div className='bg-gray-300 h-8 w-1/4 rounded'></div>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 ) : data.length === 0 ? (
//                     <div className='text-center'>
//                         <p>No products found in this category.</p>
//                     </div>
//                 ) : (
//                     <div className='relative flex items-center'>
//                         <button
//                             className='bg-white shadow-md rounded-full p-1 absolute text-2xl h-12 left-0 hidden z-10'
//                             onClick={() => scrollElement.current.scrollLeft -= 300}
//                             aria-label="Scroll left"
//                         >
//                             <FaAngleLeft />
//                         </button>

//                         <button
//                             className='bg-white shadow-md rounded-full text-2xl h-12 p-1 absolute right-0 hidden z-10'
//                             onClick={() => scrollElement.current.scrollLeft += 300}
//                             aria-label="Scroll right"
//                         >
//                             <FaAngleRight />
//                         </button>

//                         <div
//                             className='grid w-full customMax-639:overflow-y-scroll customMax-639:max-h-[80vh] grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 scrollbar-none'
//                             ref={scrollElement}
//                         >
//                             {data.map((product, index) => (
//                                 <Link to={"product/" + product?._id} key={index} className='w-full bg-white rounded-sm shadow'>
//                                     <div className='h-48 p-4 mb-2 overflow-hidden flex justify-center'>
//                                         <img
//                                             src={product.productImage[0]}
//                                             alt={product?.itemName}
//                                             className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply'
//                                         />
//                                     </div>
//                                     <div className='p-4 grid gap-3'>
//                                         <h2 className='capitalize font-medium md:text-lg text-ellipsis line-clamp-1 text-black'>
//                                             {product?.itemName}
//                                         </h2>
//                                         <p className='capitalize text-slate-500'>{product?.category}</p>
//                                         <div className='text-orange-800'>
//                                             <p>{displayPrice(product?.price, product?.currency)}</p>
//                                         </div>
//                                         <button
//                                             className='bg-green-600 hover:bg-orange-700 text-black text-md md:font-bold px-2 py-1 rounded-full'
//                                             onClick={(e) => handleAddCart(e, product?._id)}
//                                         >
//                                             Add to Cart
//                                         </button>
//                                     </div>
//                                 </Link>
//                             ))}
//                         </div>
//                     </div>
//                 )}

//             </div>
//         </div>
//     );
// };

// export default VerticalCardProduct;


import React, { useContext, useEffect, useRef, useState } from 'react';
import fetchProductsByCategory from '../helpers/FetchProductsByCategory';
import displayPrice from '../helpers/FormatPrice';
import { Link } from 'react-router-dom';
import addToCart from '../helpers/AddToCart';
import Context from '../context';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

const VerticalCardProduct = ({ localMarket, category, heading, className = '' }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1); // Track current page
    const [hasMore, setHasMore] = useState(true); // To check if more products are available
    const scrollElement = useRef(); // For horizontal scroll reference

    const { fetchUserAddToCartCount } = useContext(Context);

    const handleAddCart = async (e, id) => {
        await addToCart(e, id);
        fetchUserAddToCartCount();
    };

    // Fetch products by category with pagination
    const fetchData = async () => {
        if (loading || !hasMore) return; // Prevent additional fetch if already loading or no more products

        setLoading(true);
        const categoryProduct = await fetchProductsByCategory(localMarket, category, page, 10); // Fetch 20 items per page
        setLoading(false);

        if (categoryProduct && categoryProduct.data.length > 0) {
            setData((prevData) => [...prevData, ...categoryProduct.data]); // Append new products
            if (categoryProduct.data.length < 10) {
                setHasMore(false); // No more products if fetched items < 20
            }
        } else {
            setHasMore(false); // End of available products
        }
    };

    useEffect(() => {
        // Reset data, page, and hasMore state when localMarket or category changes
        setData([]);
        setPage(1);
        setHasMore(true);
        fetchData(); // Initial fetch on market/category change
    }, [localMarket, category]);

    useEffect(() => {
        if (page > 1) {
            fetchData(); // Fetch data when the page increments
        }
    }, [page]);

    // Detect when the user scrolls to the end of the horizontal list
    const handleScroll = () => {
        if (!scrollElement.current) return;

        const { scrollLeft, scrollWidth, clientWidth } = scrollElement.current;
        if (scrollLeft + clientWidth >= scrollWidth - 5 && hasMore) {
            setPage((prevPage) => prevPage + 1); // Load next page when near end
        }
    };

    useEffect(() => {
        const scrollRef = scrollElement.current;
        if (scrollRef) {
            scrollRef.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scrollRef) {
                scrollRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [hasMore]); // Only re-run if hasMore changes

    const scrollRight = () => {
        scrollElement.current.scrollLeft += 300;
    };

    const scrollLeft = () => {
        scrollElement.current.scrollLeft -= 300;
    };


    return (
        <div className='mt-2 mx-auto px-4 my-6 relative'>
            <div className={`customMax-800 ${className}`}>
                <h2 className='text-2xl font-semibold py-4'>{heading}</h2>

                {loading && data.length === 0 ? (
                    // Skeleton Loader
                    <div className="grid customMax-639:grid-cols-2 customMax-639:grid-rows-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 overflow-x-auto customMax-639:overflow-y-scroll customMax-639:max-h-[600px]">
                        {Array(6).fill(null).map((_, index) => (
                            <div key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow animate-pulse'>
                                <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
                                    <div className='bg-gray-300 w-full h-full'></div>
                                </div>
                                <div className='p-4 grid gap-3'>
                                    <div className='bg-gray-300 h-6 w-3/4 rounded'></div>
                                    <div className='bg-gray-300 h-4 w-1/2 rounded'></div>
                                    <div className='bg-gray-300 h-5 w-1/3 rounded'></div>
                                    <div className='bg-gray-300 h-8 w-1/4 rounded'></div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : data.length === 0 ? (
                    <div className='text-center'>
                        <p>No products found in this category.</p>
                    </div>
                ) : (
                    <div className='relative flex items-center'>
                        <button
                            className='bg-white shadow-md rounded-full p-1 absolute text-2xl h-12 left-0 hidden  z-10'
                            onClick={scrollLeft}
                            aria-label="Scroll left"
                        >
                            <FaAngleLeft />
                        </button>

                        <button
                            className='bg-white shadow-md rounded-full text-2xl h-12 p-1 absolute right-0 hidden z-10'
                            onClick={scrollRight}
                            aria-label="Scroll right"
                        >
                            <FaAngleRight />
                        </button>

                        <div className='grid w-full customMax-639:overflow-y-scroll customMax-639:max-h-[80vh] grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 scrollbar-none' ref={scrollElement}>
                            {data.map((product, index) => (
                                <Link to={"product/" + product?._id} key={index} className='w-full  bg-white rounded-sm shadow'>
                                    <div className='h-48 p-4 mb-2 overflow-hidden flex justify-center'>
                                        <img
                                            src={product.productImage[0]}
                                            alt={product?.itemName}
                                            className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply'
                                        />
                                    </div>
                                    <div className='p-4 grid '>
                                        <h2 className='capitalize font-medium md:text-lg text-ellipsis line-clamp-1 text-black'>
                                            {product?.itemName}
                                        </h2>
                                        <p className='capitalize text-slate-500'>{product?.category}</p>
                                        <div className='text-orange-800'>
                                            <p>{displayPrice(product?.price, product?.currency)}</p>
                                        </div>
                                        <button
                                            className='bg-white border hover:bg-orange-700 text-black text-md md:font-bold px-2 py-1 rounded-full'
                                            onClick={(e) => handleAddCart(e, product?._id)}
                                        >
                                            Add to Cart
                                        </button>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VerticalCardProduct;




// import React, { useContext, useEffect, useRef, useState } from 'react';
// import fetchProductsByCategory from '../helpers/FetchProductsByCategory';
// import displayPrice from '../helpers/FormatPrice';
// import { Link } from 'react-router-dom';
// import addToCart from '../helpers/AddToCart';
// import Context from '../context';
// import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';

// const VerticalCardProduct = ({ localMarket, category, heading, className = '' }) => {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [page, setPage] = useState(1); // Tracks the current page for pagination
//     const [hasMore, setHasMore] = useState(true); // Tracks if there are more products to load
//     const scrollElement = useRef();

//     const { fetchUserAddToCartCount } = useContext(Context);

//     const handleAddCart = async (e, id) => {
//         await addToCart(e, id);
//         fetchUserAddToCartCount();
//     };

//     // Fetch products by category with pagination
//     const fetchData = async () => {
//         if (loading || !hasMore) return; // Prevent loading if already in progress or no more products

//         setLoading(true);
//         const categoryProduct = await fetchProductsByCategory(localMarket, category, page, 20); // Fetch 20 items per page
//         setLoading(false);

//         if (categoryProduct && categoryProduct.data.length > 0) {
//             setData((prevData) => [...prevData, ...categoryProduct.data]); // Append new products
//             if (categoryProduct.data.length < 20) {
//                 setHasMore(false); // If fewer than 20 products are fetched, no more pages
//             }
//         } else {
//             setHasMore(false); // No more products to load
//         }
//     };

//     useEffect(() => {
//         // Reset the data, page, and hasMore state when localMarket changes
//         setData([]);
//         setPage(1); // Reset to page 1
//         setHasMore(true); // Reset pagination

//         fetchData()
//     }, [localMarket]);
    
//     useEffect(() => {
//         if (page > 1) {
//           fetchData();
//         }
//       }, [page]); // Fetch data when page or localMarket changes

//     // Detect when the user scrolls to the right end of the horizontal list
//     const handleScroll = () => {
//         if (!scrollElement.current) return;
//         const { scrollLeft, scrollWidth, clientWidth } = scrollElement.current;
//         if (scrollLeft + clientWidth >= scrollWidth - 5 && hasMore) {
//             setPage((prevPage) => prevPage + 1); // Load the next page
//         }
//     };

//     useEffect(() => {
//         if (scrollElement.current) {
//             scrollElement.current.addEventListener('scroll', handleScroll);
//         }
//         return () => {
//             if (scrollElement.current) {
//                 scrollElement.current.removeEventListener('scroll', handleScroll);
//             }
//         };
//     }, [hasMore]);

//     const scrollRight = () => {
//         scrollElement.current.scrollLeft += 300;
//     };

//     const scrollLeft = () => {
//         scrollElement.current.scrollLeft -= 300;
//     };

//     return (
//         <div className='container mx-auto px-4 my-6 relative'>
//             <div className={`customMax-800 ${className}`}>
//                 <h2 className='text-2xl font-semibold py-4'>{heading}</h2>

//                 {loading && data.length === 0 ? (
//                     // Skeleton Loader
//                     <div className="grid customMax-639:grid-cols-2 customMax-639:grid-rows-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 overflow-x-auto customMax-639:overflow-y-scroll customMax-639:max-h-[600px]">
//                         {Array(6).fill(null).map((_, index) => (
//                             <div key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow animate-pulse'>
//                                 <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
//                                     <div className='bg-gray-300 w-full h-full'></div>
//                                 </div>
//                                 <div className='p-4 grid gap-3'>
//                                     <div className='bg-gray-300 h-6 w-3/4 rounded'></div>
//                                     <div className='bg-gray-300 h-4 w-1/2 rounded'></div>
//                                     <div className='bg-gray-300 h-5 w-1/3 rounded'></div>
//                                     <div className='bg-gray-300 h-8 w-1/4 rounded'></div>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 ) : data.length === 0 ? (
//                     <div className='text-center'>
//                         <p>No products found in this category.</p>
//                     </div>
//                 ) : (
//                     <div className='relative flex items-center'>
//                         <button
//                             className='bg-white shadow-md rounded-full p-1 absolute text-2xl h-12 left-0 hidden  z-10'
//                             onClick={scrollLeft}
//                             aria-label="Scroll left"
//                         >
//                             <FaAngleLeft />
//                         </button>

//                         <button
//                             className='bg-white shadow-md rounded-full text-2xl h-12 p-1 absolute right-0 hidden z-10'
//                             onClick={scrollRight}
//                             aria-label="Scroll right"
//                         >
//                             <FaAngleRight />
//                         </button>

//                         <div className='grid w-full customMax-639:overflow-y-scroll customMax-639:max-h-[80vh] grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 scrollbar-none' ref={scrollElement}>
//                             {data.map((product, index) => (
//                                 <Link to={"product/" + product?._id} key={index} className='w-full  bg-white rounded-sm shadow'>
//                                     <div className='h-48 p-4 mb-2 overflow-hidden flex justify-center'>
//                                         <img
//                                             src={product.productImage[0]}
//                                             alt={product?.itemName}
//                                             className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply'
//                                         />
//                                     </div>
//                                     <div className='p-4 grid gap-3'>
//                                         <h2 className='capitalize font-medium md:text-lg text-ellipsis line-clamp-1 text-black'>
//                                             {product?.itemName}
//                                         </h2>
//                                         <p className='capitalize text-slate-500'>{product?.category}</p>
//                                         <div className='text-orange-800'>
//                                             <p>{displayPrice(product?.price, product?.currency)}</p>
//                                         </div>
//                                         <button
//                                             className='bg-green-600 hover:bg-orange-700 text-black text-md md:font-bold px-2 py-1 rounded-full'
//                                             onClick={(e) => handleAddCart(e, product?._id)}
//                                         >
//                                             Add to Cart
//                                         </button>
//                                     </div>
//                                 </Link>
//                             ))}
//                         </div>
//                     </div>
//                 )}

//             </div>
//         </div>
//     );

// };

// export default VerticalCardProduct;


     // return (
    //     <div className='container mx-auto px-4 my-6 relative'>
    //         <div className={`customMax-800 ${className}`}>
    //             <h2 className='text-2xl font-semibold py-4'>{heading}</h2>

    //             {loading && data.length === 0 ? (
    //                 // Skeleton Loader
    //                 <div className='flex items-center gap-4 md:gap-6 overflow-scroll scrollbar-none'>
    //                     {Array(6).fill(null).map((_, index) => (
    //                         <div key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow animate-pulse'>
    //                             <div className='bg-slate-200 h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
    //                                 <div className='bg-gray-300 w-full h-full'></div>
    //                             </div>
    //                             <div className='p-4 grid gap-3'>
    //                                 <div className='bg-gray-300 h-6 w-3/4 rounded'></div>
    //                                 <div className='bg-gray-300 h-4 w-1/2 rounded'></div>
    //                                 <div className='bg-gray-300 h-5 w-1/3 rounded'></div>
    //                                 <div className='bg-gray-300 h-8 w-1/4 rounded'></div>
    //                             </div>
    //                         </div>
    //                     ))}
    //                 </div>
    //             ) : data.length === 0 ? (
    //                 <div className='text-center'>
    //                     <p>No products found in this category.</p>
    //                 </div>
    //             ) : (
    //                 <div className='relative flex items-center'>
    //                     <button
    //                         className='bg-white shadow-md rounded-full p-1 absolute text-2xl h-12 left-0 hidden md:block z-10'
    //                         onClick={scrollLeft}
    //                         aria-label="Scroll left"
    //                     >
    //                         <FaAngleLeft />
    //                     </button>

    //                     <button
    //                         className='bg-white shadow-md rounded-full text-2xl h-12 p-1 absolute right-0 hidden md:block z-10'
    //                         onClick={scrollRight}
    //                         aria-label="Scroll right"
    //                     >
    //                         <FaAngleRight />
    //                     </button>

    //                     <div className='flex items-center gap-4 md:gap-6 overflow-scroll scrollbar-none' ref={scrollElement}>
    //                         {data.map((product, index) => (
    //                             <Link to={"product/" + product?._id} key={index} className='w-full min-w-[280px] md:min-w-[320px] max-w-[280px] md:max-w-[320px] bg-white rounded-sm shadow'>
    //                                 <div className='h-48 p-4 min-w-[280px] md:min-w-[145px] flex justify-center items-center'>
    //                                     <img
    //                                         src={product.productImage[0]}
    //                                         alt={product?.itemName}
    //                                         className='object-scale-down h-full hover:scale-110 transition-all mix-blend-multiply'
    //                                     />
    //                                 </div>
    //                                 <div className='p-4 grid gap-3'>
    //                                     <h2 className='capitalize font-medium md:text-lg text-ellipsis line-clamp-1 text-black'>
    //                                         {product?.itemName}
    //                                     </h2>
    //                                     <p className='capitalize text-slate-500'>{product?.category}</p>
    //                                     <div className='text-orange-800'>
    //                                         <p>{displayPrice(product?.price, product?.currency)}</p>
    //                                     </div>
    //                                     <button
    //                                         className='bg-green-600 hover:bg-orange-700 text-black text-md md:font-bold px-2 py-1 rounded-full'
    //                                         onClick={(e) => handleAddCart(e, product?._id)}
    //                                     >
    //                                         Add to Cart
    //                                     </button>
    //                                 </div>
    //                             </Link>
    //                         ))}
    //                     </div>
    //                 </div>
    //             )}

    //         </div>
    //     </div>
    // );


