import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaExclamationCircle } from 'react-icons/fa';

const PaymentCancel = () => {
    const [searchParams] = useSearchParams();
    const purchaseId = searchParams.get('purchaseId');

    return (
        <motion.div
            className="w-full flex flex-col items-center justify-center min-h-screen bg-gray-100"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
        >
            {/* Cancel Icon */}
            <motion.div
                className="text-red-500 text-6xl mb-6"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
            >
                <FaExclamationCircle />
            </motion.div>

            {/* Heading */}
            <motion.h1
                className="text-3xl font-bold text-red-600 mb-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
            >
                Payment Canceled
            </motion.h1>

            {/* Message */}
            <motion.p
                className="text-lg text-gray-700 mb-6 text-center max-w-md"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
            >
                It seems the payment was unsuccessful. Please click below to continue shopping <strong className="text-gray-900">{purchaseId}</strong>
            </motion.p>

            {/* Back to Cart Button */}
            <motion.a
                href="/cart"
                className="px-6 py-2 bg-red-500 text-white rounded shadow hover:bg-red-600 transition"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
            >
                Back to Cart
            </motion.a>
        </motion.div>
    );
};

export default PaymentCancel;

