import React, { useState, useEffect, useContext } from 'react';
import Context from '../../context';
import summaryApi from '../../common';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const SellerUpdateAccount = () => {
  const [formData, setFormData] = useState({
    profilePic: '',
    mobileNumber: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { fetchUserdetails } = useContext(Context);
  const navigate = useNavigate();

  // Fetch existing seller data when the component mounts
  useEffect(() => {
    const fetchSellerDetails = async () => {
        try {
          const userDetails = await fetchUserdetails();
          if (userDetails) {
            setFormData((prevState) => ({ ...prevState, ...userDetails }));
          } else {
            console.error('User details are undefined or null');
          }

          if (!userDetails || typeof userDetails !== 'object') {
            console.warn('Invalid userDetails data:', userDetails);
            return;
          }
        } catch (error) {
          console.error('Error fetching seller details:', error);
        }
      };
      
      
       

    fetchSellerDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };


  const handleUploadProfilePic = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("files", file);
    formData.append("type", "profilePic"); // Specify the file type
  
    try {
      const response = await fetch(summaryApi.imageUpload.url, {
        method: summaryApi.imageUpload.method,
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error("Failed to upload profile picture");
      }
  
      const { imageUrls } = await response.json();
      setFormData((prev) => ({ ...prev, profilePic: imageUrls[0] }));
    } catch (error) {
      console.error("Error uploading profile picture:", error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataCopy = { ...formData };
  
      // Convert files to strings or remove them if unchanged
      ['profilePic'].forEach((fileField) => {
        if (formData[fileField] instanceof File) {
          // Process the file upload (e.g., upload to a server or cloud storage)
          // Example: Replace with the file's URL after upload
          formDataCopy[fileField] = 'uploaded_file_url';
        } else if (typeof formData[fileField] !== 'string') {
          delete formDataCopy[fileField];
        }
      });
  
      const response = await fetch(summaryApi.updateSellerAccount.url, {
        method: summaryApi.updateSellerAccount.method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataCopy),
      });
  
      const result = await response.json();
      if (response.ok) {
        toast.success('Profile updated successfully!');
        await fetchUserdetails();
        navigate('/dashboard/all-products');
      } else {
        toast.error(result.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('An unexpected error occurred');
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
      <h2 className="text-orange-600 text-2xl font-bold text-center mb-6">Update Your Profile</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Profile Picture</label>
          <input
            type="file"
            name="profilePic"
            onChange={handleUploadProfilePic}
            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-orange-100 file:text-orange-600 hover:file:bg-orange-200"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Mobile  Number</label>
          <input
            type="text"
            name="mobileNumber"
            value={formData.mobileNumber|| ''}
            onChange={handleChange}
            className="w-full border-gray-300 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
          />
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full text-white font-semibold py-2 px-4 rounded-md shadow-sm ${
            isSubmitting
              ? 'bg-orange-400 cursor-not-allowed'
              : 'bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2'
          }`}
        >
          {isSubmitting ? 'Updating...' : 'Update Profile'}
        </button>
      </form>
    </div>
  );
  
};

export default SellerUpdateAccount;
