const productCategory = [
    {id : 1, label : "All", value : "All"},
    {id : 2, label : "Food", value : "Food"}, 
    {id : 3, label : "Clothing", value : "Clothing" }, 
    {id : 4, label : "Beauty Care", value : "Beauty Care"},
    {id : 5, label : "Custom-made", value : "Custom-made"},
    {id : 6, label : "Books", value : "Books"}, 
    {id : 7, label : "Land & Property", value : "Land & Property"}, 
    {id : 8, label : "Arts & Crafts", value : "Arts & Crafts"}, 
    {id : 9, label : "Services", value : "Services"},
    {id : 10, label : "Accessories", value : "Accessories"}, 
    {id : 11, label : "Electronics", value : "Electronics"} ,
    {id : 12, label : "Furniture", value : "Furniture"} 
]

export default productCategory