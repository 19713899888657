

import React, { useState } from "react";

const CustomDropdown = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative w-full ">
      {/* Dropdown button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-white text-base px-1.5 custom-375:px-0 rounded w-full"
      >
        {value || "Select Market"}
      </button>

      {/* Dropdown list */}
      <div className="absolute right-0">
        {isOpen && (
        <ul className=" bg-white text-black w-full border rounded shadow-lg mt-0 z-50">
          {options.map((option) => (
            <li
              key={option}
              className="cursor-pointer px-3 py-2 hover:bg-gray-300"
              onClick={() => {
                onChange(option);
                setIsOpen(false);
              }}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
      </div>
      
    </div>
  );
};

export default CustomDropdown;
