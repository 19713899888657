import React from "react";

const AboutDropShipping = () => {
  return (
    <div className="max-w-4xl mx-auto px-6 py-12 font-sans">
      <h1 className="text-center text-3xl font-bold mb-6">About Drop Shipping</h1>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">What is Drop Shipping?</h2>
        <p>
          Drop shipping is a retail fulfillment method where a store doesn’t keep
          the products it sells in stock. Instead, when a store sells a product,
          it purchases the item from a third party and has it shipped directly to
          the customer. This means the seller doesn’t have to handle the product
          directly.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">How Does Drop Shipping Work?</h2>
        <p>
          The process is straightforward:
          <br />
          1. A customer places an order on your online store.
          <br />
          2. You forward the order details to a supplier.
          <br />
          3. The supplier ships the product directly to the customer.
          <br />
          As a result, you don’t need to manage inventory or worry about
          shipping logistics.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">Benefits of Drop Shipping</h2>
        <p>
          - Low initial investment since there’s no need to purchase inventory upfront.
          <br />
          - Flexibility to operate from anywhere with an internet connection.
          <br />
          - Broad product offerings as you can partner with multiple suppliers.
          <br />
          - Easy to scale as your business grows without worrying about logistics.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">
          Opportunities for Developing Nations
        </h2>
        <p>
          Drop shipping offers a unique opportunity for entrepreneurs in
          developing nations. With minimal startup costs, it allows individuals
          to launch an online business without needing substantial capital. It
          also provides access to a global market, enabling entrepreneurs to
          overcome local economic limitations and connect with customers
          worldwide. The flexibility of drop shipping also means they can manage
          their business alongside other commitments.
        </p>
      </section>
    </div>
  );
};

export default AboutDropShipping;
