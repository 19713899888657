import HomeMarketOG from '../assest/og_brand.png'

import React from 'react'

const OG = () => {
  return (
    <div>
        <img className='w-[1200px] h-[630px]' src={HomeMarketOG} alt='HomeMarketOG' />
    </div>
  )
}

export default OG

