import React from "react";

const LogisticsInformation = () => {
  return (
    <div className="max-w-4xl mx-auto px-6 py-12 font-sans">
      <h1 className="text-center text-3xl font-bold mb-6">Our Logistics and Delivery System</h1>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">Delivery Partners</h2>
        <p>
          At HomeMarket2U, we partner with trusted logistics providers, FedEx and
          DHL, to ensure reliable and efficient delivery of your orders. These
          global leaders in logistics help us deliver products to your doorstep
          safely and on time.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">Free Delivery Policy</h2>
        <p>
          We offer free delivery for purchases meeting the following conditions:
        </p>
        <ul className="list-disc list-inside">
          <li>
            Orders from Nigeria weighing up to 10kg, maximum dimension 35 x 35 x 35 qualify for free delivery to
            the UK or the USA.
          </li>
          <li>
            Orders from India weighing up to 10kg maximum dimension 35 x 35 x 35 qualify for free delivery to
            the UK or the USA.
          </li>
        </ul>
        <p className="mt-4">
          Free delivery eligibility is determined by the product cost, and any
          exceptions or additional conditions will be clearly specified in the
          product description on the product page.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">Delivery Timeline</h2>
        <p>
          - For orders from Nigeria, delivery to the UK or USA typically takes
          4-8 days.
          <br />
          - For orders from India, delivery to the UK or USA typically takes 3-7
          days.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">Important Information</h2>
        <p>
          Please note that any delivery terms outside the standard policies listed
          above will be specified on the respective product’s description page.
          We aim to provide clear and transparent information to ensure a
          seamless shopping experience.
        </p>
      </section>
    </div>
  );
};

export default LogisticsInformation;
