import React from "react";

const ProductPackaging = () => {
  return (
    <div className="max-w-4xl mx-auto px-6 py-12 font-sans">
      <h1 className="text-center text-3xl font-bold mb-6">Product Packaging Guidelines</h1>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">Fragile Products</h2>
        <p>
          Fragile products require extra care during packaging to ensure they
          arrive safely. Here’s how we handle fragile items:
        </p>
        <ul className="list-disc list-inside mt-4">
          <li>
            Items are wrapped in bubble wrap to provide cushioning against impact.
          </li>
          <li>
            High-quality, sturdy boxes are used to prevent crushing or damage.
          </li>
          <li>
            Additional padding, such as foam peanuts or air pillows, is added to
            fill empty spaces inside the box.
          </li>
          <li>
            Labels indicating “Fragile” are affixed to the package for careful handling.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">Non-Fragile Products</h2>
        <p>
          Non-fragile products also require secure packaging to ensure they
          remain in good condition during transit. Here’s our approach:
        </p>
        <ul className="list-disc list-inside mt-4">
          <li>
            Products are packed in durable materials such as cardboard boxes or
            poly mailers.
          </li>
          <li>
            Items are sealed tightly to prevent shifting or exposure to external elements.
          </li>
          <li>
            Waterproof materials or liners are used where necessary to protect
            against moisture.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">Specialized Products</h2>
        <p>
          Some products, such as perishable items, electronics, or heavy goods,
          require specialized packaging:
        </p>
        <ul className="list-disc list-inside mt-4">
          <li>
            Perishables: Insulated packaging and cooling elements are used to
            maintain freshness.
          </li>
          <li>
            Electronics: Anti-static materials and secure padding ensure safety
            against shocks and static electricity.
          </li>
          <li>
            Heavy Goods: Reinforced boxes and extra strapping are used for
            additional support.
          </li>
        </ul>
      </section>

      <section className="mb-8 text-center">
        <p className="mb-4">
          For more details about our packaging process and tips for secure
          shipping, watch our informative video.
        </p>
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
          onClick={() => window.open('https://www.youtube.com/results?search_query=how+to+package+product+for+ecommerce', '_blank')}
        >
          Learn More on YouTube
        </button>
      </section>
    </div>
  );
};

export default ProductPackaging;
