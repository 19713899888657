

import React, { useContext, useEffect, useState } from 'react';
import Logo from './Logo';
import { FiSearch } from "react-icons/fi";
import { FaLocationDot } from "react-icons/fa6";
import { FaRegUserCircle } from "react-icons/fa";
import { FaCartArrowDown } from "react-icons/fa";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import summaryApi from '../common';
import { toast } from "react-toastify";
import { setUserDetails } from '../store/userSlice';
import ROLE from '../common/role';
import productCategory from '../helpers/ProductCategory';
import Context from '../context';
import { MarketContext } from '../context/MarketContext'; // Import the context
import { FaHome } from "react-icons/fa";
import CustomDropdown from '../helpers/CustomMarketDropdowb';


function Header() {
  const user = useSelector(state => state?.user?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Add useNavigate to programmatically navigate
  const [menuDisplay, setMenuDisplay] = useState(false);
  const context = useContext(Context);
  const { localMarket, updateMarket } = useContext(MarketContext); // Get market from context
  const [data, setData] = useState({ category: 'All', itemName: '' });
  const location = useLocation();

  

  //const [search, setSearch] = useState(searchInput?.search?.split("=")[0])

  // const handleLogout = async () => {
  //   const fetchData = await fetch(summaryApi.logout_user.url, {
  //     method: summaryApi.logout_user.method,
  //     credentials: "include"
  //   });

  //   const data = await fetchData.json();

  //   if (data.success) {
  //     toast.success(data.message);
  //     dispatch(setUserDetails(null));
  //     context.fetchUserAddToCartCount();
  //     navigate('/')
  //     console.log('user after logout:', user)
  //   }

  //   if (data.error) {
  //     toast.error(data.message);
  //   }
  // };

  const handleLogout = async () => {
    try {
      const fetchData = await fetch(summaryApi.logout_user.url, {
        method: summaryApi.logout_user.method,
        credentials: "include",
      });
  
      const data = await fetchData.json();
  
      if (data.success) {
        // Display a success message
        toast.success(data.message);
  
        // Clear Redux state
        dispatch(setUserDetails(null));

        //clear localStorage
        localStorage.removeItem("userRole")
  
        // Clear any user-related context data
        context.fetchUserAddToCartCount();
  
        // Navigate to the home page
        navigate('/');
        
      } else if (data.error) {
        // Display an error message
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Logout failed:", error);
      toast.error("Something went wrong during logout. Please try again.");
    }
  };

 
  const handleMarketChange = (value) => {
    updateMarket(value);
  };

  
  useEffect(() => {
    if (location.pathname === '/') {
      setData({ category: '', itemName: '' }); // Clear inputs
    }
  }, [location.pathname]); // Dependency on the current path

  const handleSearch = async () => {
    const { category, itemName } = data;

     // If no category selected, default to "All"
     const selectedCategory = category || "All";

    // Get market from local storage
    const market = localStorage.getItem('localMarket');
    if (!market) {
      console.error("No market selected!");
      return;
    }

    try {
      const searchParams = new URLSearchParams();
      searchParams.append('category', selectedCategory);
      if (market) searchParams.append('market', market);
      if (itemName) searchParams.append('itemName', itemName);


      //const priceFilter = 'highest'; // Sorting example
      //searchParams.append('price', priceFilter);

      // Redirect to search results page with query parameters
      navigate(`/search?${searchParams.toString()}`);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  
 
  return (
    <header className=' header fixed top-0 left-0 w-full z-50' >
      <div className='header-container h-16 bg-slate-900 shadow-sm w-full '>
        <div className='lg-screen-container h-full w-full max-w-full flex justify-center '>
          <div className='inside-lg-container h-full w-full max-w-full container custom-850-1023:mx-1 mt-0 flex justify-between text-white my-2 customMax400:ml-0.5'>
            <div className=''>
              <Link to={"/"} >
                <Logo />  
              </Link>
            </div>
    
            <div className='market flex items-center justify-center border-2 border-white rounded my-1 Custom390-600:px-2'>
              <h5 className='h5-font flex items-center customMax600:max-w-[91px] customMax600:p-0 customMax600:text-sm p-2 '>
                <div className='locotion-icon text-2xl '>
                  <FaLocationDot />
                </div>
                <div className='local-market'>
                  Market <br />
                  <CustomDropdown
                    options={["India", "Nigeria"]}
                    value={localMarket || ''}
                    onChange={handleMarketChange}
                    className="w-full max-w-full"
                  />
                  {/* <select
                      value={localMarket || ''} // Show the selected market or an empty value
                      onChange={handleMarketChange} // Update the market using the hook
                      className='local-market-option customMax600:text-sm text-xl text-white bg-slate-900 outline-none border-none cursor-pointer flex -mt-0.5'
                    >
                      <option value='India'>India</option>
                      <option value='Nigeria'>Nigeria</option>
                      {/* Add more options as needed */}
                    {/* </select> */}
                </div> 
              </h5>
            </div>
    
            <div onSubmit={handleSearch} className='search-container hidden custom-847:flex items-center justify-between'>
              <div className='category-div flex items-center w-full max-w-full'>
                <div className='searchCategory'>
                  <select
                    className='select-box h-9 border-black rounded-l-md bg-slate-100 text-slate-500'
                    onChange={(e) => setData({ ...data, category: e.target.value })} 
                    value={data.category} // Set category from state
                  >
                    {productCategory.map((category) => (
                      <option value={category.value} key={category.id}>
                        {category.label}
                      </option>
                    ))}
                  </select>
                </div>

                <input 
                  placeholder='search product...' 
                  className='search-input flex-grow p-2 pt-1 h-9 outline-none focus-within:shadow-sm text-black'
                  onChange={(e) => setData({ ...data, itemName: e.target.value })} // Set item name
                  value={data.itemName} // Set item name from state
                  onKeyDown={(e) => {
                    // Check if Enter is pressed and screen width is greater than or equal to medium (768px)
                    if (e.key === 'Enter' && window.innerWidth >= 768) {
                      handleSearch();  // Call handleSearch only on md: and above
                    }
                  }}
                />

                <div className='search-icon text-lg min-w-[50px] h-9 p-2 bg-orange-600 flex items-center justify-center rounded-r' onClick={handleSearch}>
                  <FiSearch />
                </div>
              </div>
            </div>

            <div className={`user-profile flex gap-2 md:gap-4 items-center  ${user ? 'pr-5 md:pr-10 gap-3' : 'pr-2'}`}>
              <div className='inside-user-profile relative flex justify-center'>
    
                {
                  user?._id && (
                    <div
                      className="image-and-icon -mr-1 flex text-3xl cursor-pointer "
                    >
                      {
                        // Prioritize Home icon for Admin and Seller
                        (user?.role === ROLE.ADMIN || user?.role === ROLE.BUSINESSOWNER) ? (
                            <FaHome
                              className="w-7 h-10 rounded"
                              title={user?.role === ROLE.ADMIN ? 'Admin-Home' : 'Seller-Home'}
                              onClick={() => setMenuDisplay((prev) => !prev)}
                            />
                        ) :
                      (
                          // Show profile picture or user icon for other users
                          user?.profilePic ? (
                            <img 
                              src={user?.profilePic} 
                              className="user-pic w-10 h-10 rounded-full" 
                              alt={user?.name} 
                              referrerPolicy="no-referrer"
                              onClick={() => setMenuDisplay((prev) => !prev)} // Toggle menu for Admin or Seller
                            />
                          ) : (
                            <FaRegUserCircle onClick={() => setMenuDisplay((prev) => !prev)} />
                          )
                        )
                      }
                    </div>
                  )
                }

              {
                menuDisplay && (
                  <div
                    className="dashboard-dialogue absolute bg-white text-black bottom-0 top-11 h-fit p-2 shadow-lg rounded-md border border-gray-300"
                    style={{ zIndex: 50 }}
                  >
                    <nav className='border'>
                      {(user?.role === ROLE.ADMIN || user?.role === ROLE.BUSINESSOWNER) && (
                        <Link 
                          to="dashboard/all-products"
                          className="block hover:bg-gray-200 px-4 py-2 rounded-md transition duration-300 border"
                          onClick={() => setMenuDisplay(false)}
                        >
                          Dashboard
                        </Link>
                      )}
                      <Link
                        to={"purchase-history"}
                        className="dashboard-button border whitespace-nowrap block hover:bg-slate-100 px-4 py-2 rounded-md text-gray-700 font-medium transition duration-300"
                        onClick={() => setMenuDisplay((prev) => !prev)} // Close menu when navigating
                      >
                        Purchase History
                      </Link>
                      {user?._id && (
                        <button 
                          onClick={() => {
                            handleLogout();
                            setMenuDisplay(false);
                          }}
                          className="block border w-full text-left hover:bg-red-100 px-4 py-2 rounded-md text-gray-700 font-medium transition duration-300"
                        >
                          Logout
                        </button>
                      )}
                    </nav>
                  </div>
                )
              }

              </div> 

                <Link to={"/cart"} className='cart-container text-2xl cursor-pointer relative max-w-full'>
                  <span><FaCartArrowDown /></span>
      
                  <div className='cart-number-div bg-orange-600 text-white, w-5 h-5 rounded-full p-1 flex items-center justify-center absolute -top-2 -right-2'>
                    <p className='cart-total text-xs'>{context?.cartProductCount || 0}</p>
                  </div>
                </Link>
      
                <div className='login-logout-container m-2 bg-white text-black rounded-md px-2 md:px-3 py-1 hover:bg-slate-300'>
                  
                    { 
                      !user?._id &&  ( <Link to={"/login"} >Login</Link>)
                    }
                  
                </div>
              
            </div>
          </div>
        </div>
        
  
           {/* Search bar for smaller screens */}
        <div className='customMax-800:flex max-w-full hidden  px-1.5 pb-1.5 bg-slate-500'>
          <div className='flex items-center w-full max-w-full '>
            <div className='searchCategory '>
              <select
                    className='select-box h-9 bg-slate-300 rounded-l border-white'
                    onChange={(e) => setData({ ...data, category: e.target.value })} // Set category
                    value={data.category}
                  >
                    {productCategory.map((el) => (
                      <option value={el.value} key={el.id}>
                        {el.label}
                      </option>
                    ))}
              </select>
            </div>
            <input
              placeholder='search product...'
              className='flex-grow w-full max-w-full p-2 pt-1 h-9 outline-none focus-within:shadow-sm text-black'
              onChange={(e) => setData({ ...data, itemName: e.target.value })} // Set item name
              value={data.itemName}
            />
            <div className='text-lg min-w-[50px] h-9 p-2 bg-orange-600 flex items-center justify-center rounded-r'
             onClick={handleSearch} // Add click event to search
             >
              <FiSearch />
            </div>
          </div>
        </div>
      </div>
      
    </header>
  )
}


export default Header

