import React from 'react'
import Logo from '../assest/android-chrome-512x512.png'

const ImageLogo = () =>{
  return (
    <div >
      <img src={Logo} alt='HomeMarket2ULogo' className='w-[512px] h-[512px]' />
    </div>
  )
}

export default ImageLogo