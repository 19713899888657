import React, { useState } from 'react';
import summaryApi from '../../common';
import { toast } from 'react-toastify';

const ShipmentForm = ({ onSubmit, initialData = {} }) => {
  const [formData, setFormData] = useState({
    origin: initialData.origin || '',
    destination: initialData.destination || '',
    method: initialData.method || '',
    weight: initialData.weight || '',
    length: initialData.length || '', // New field
    width: initialData.width || '',  // New field
    height: initialData.height || '', // New field
    cost: initialData.cost || '',
    duration: initialData.duration || ''
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    // Combine dimensions into a single field
    const dimensions = `${formData.length} x ${formData.width} x ${formData.height}`;
    
    const response = await fetch(summaryApi.shipmentRate.url, {
      method: summaryApi.shipmentRate.method,
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        ...formData,
        dimensions, // Send combined dimensions to the server
      }),
    });

    const responseData = await response.json();

    try {
      if (responseData.success) {
        toast.success(responseData.message);
        setLoading(false)
        setFormData({
          origin: '',
          destination: '',
          method: '',
          weight: '',
          length: '',
          width: '',
          height: '',
          cost: '',
          duration: ''
        });
      } else {
        toast.error(responseData.message || 'Sorry, could not create shipment rate');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error creating shipment rate:', error);
      toast.error('Something went wrong. Please try again.');
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 border rounded">
      <div className="mb-4">
        <label className="block font-bold mb-1">Origin</label>
        <input
          type="text"
          name="origin"
          value={formData.origin}
          onChange={handleChange}
          className="w-full border rounded p-2"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-1">Destination</label>
        <input
          type="text"
          name="destination"
          value={formData.destination}
          onChange={handleChange}
          className="w-full border rounded p-2"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-1">Method</label>
        <select
          name="method"
          value={formData.method}
          onChange={handleChange}
          className="w-full border rounded p-2"
        >
          <option value="Air cargo">Air cargo</option>
          <option value="Water wargo">Water cargo</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-1">Weight</label>
        <input
          type="text"
          name="weight"
          value={formData.weight}
          onChange={handleChange}
          className="w-full border rounded p-2"
          required
        />
      </div>
      {/* Dimensions Section */}
      <div className="mb-4">
        <label className="block font-bold mb-1">Dimensions (Length x Width x Height)</label>
        <div className="grid grid-cols-3 gap-2">
          <input
            type="text"
            name="length"
            value={formData.length}
            onChange={handleChange}
            className="border rounded p-2"
            placeholder="Length"
            required
          />
          <input
            type="text"
            name="width"
            value={formData.width}
            onChange={handleChange}
            className="border rounded p-2"
            placeholder="Width"
            required
          />
          <input
            type="text"
            name="height"
            value={formData.height}
            onChange={handleChange}
            className="border rounded p-2"
            placeholder="Height"
            required
          />
        </div>
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-1">Cost</label>
        <input
          type="text"
          name="cost"
          value={formData.cost}
          onChange={handleChange}
          className="w-full border rounded p-2"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block font-bold mb-1">Duration</label>
        <input
          type="text"
          name="duration"
          value={formData.duration}
          onChange={handleChange}
          className="w-full border rounded p-2"
          required
        />
      </div>
      <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded" disabled={loading}>
        {loading ? 'Loading...' : 'Save'}
      </button>
    </form>
  );
};

export default ShipmentForm;
