import React from "react";

const AboutAffiliateMarketing = () => {
  return (
    <div className="max-w-4xl mx-auto px-6 py-12 font-sans">
      <h1 className="text-center text-3xl font-bold mb-6">About Affiliate Marketing</h1>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">What is Affiliate Marketing?</h2>
        <p>
          Affiliate marketing is a performance-based marketing strategy where
          individuals or businesses earn a commission by promoting the products
          or services of another company. Affiliates share a unique tracking
          link, and when someone makes a purchase through that link, the
          affiliate earns a percentage of the sale.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">How Does It Work?</h2>
        <p>
          The process is simple: affiliates join a program, promote products or
          services through their website, social media, or other channels, and
          earn a commission for every successful referral. This model benefits
          both the company and the affiliate, creating a win-win scenario.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">Benefits of Affiliate Marketing</h2>
        <p>
          - Low-cost start-up for affiliates.
          <br />
          - Passive income potential.
          <br />
          - Flexibility to work from anywhere.
          <br />
          - Scalable model that grows with effort.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">Why Choose Affiliate Marketing?</h2>
        <p>
          Affiliate marketing is a great way to monetize your online presence
          while partnering with reputable brands. It requires minimal risk and
          allows you to focus on your strengths, such as content creation or
          social media engagement.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-center text-2xl font-semibold mb-4">Our Journey in Affiliate Marketing</h2>
        <p>
          While affiliate marketing offers immense potential, we are currently
          in the planning stages and have not started our affiliate marketing
          program yet. We are working hard to create a transparent, efficient,
          and rewarding system for all our future partners. Stay tuned for
          updates!
        </p>
      </section>
    </div>
  );
};

export default AboutAffiliateMarketing;
