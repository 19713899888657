//current product, pagination working fine but filter is inconsistent
// import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { IoMdMenu } from "react-icons/io";
// import productCategory from '../helpers/ProductCategory';
// import AllVerticalProductCard from '../components/AllVerticalProductCard';
// import summaryApi from '../common';
// import { MarketContext } from '../context/MarketContext';

// const CategoryProduct = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [sidebarVisible, setSidebarVisible] = useState(false);
//   const [page, setPage] = useState(1);
//   const [hasMore, setHasMore] = useState(true);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { localMarket } = useContext(MarketContext);
//   const [filterCategoryList, setFilterCategoryList] = useState([]);
//   const [sortBy, setSortBy] = useState("");
//   const sidebarRef = useRef(null);
//   const observerRef = useRef();
//   const pageSize = 20; // Define your page size limit here

//   const urlSearch = new URLSearchParams(location.search);
//   const urlCategoryListinArray = urlSearch.getAll("category");

//   const initializeCategories = () => {
//     const categoriesFromURL = {};
//     urlCategoryListinArray.forEach((category) => {
//       categoriesFromURL[category] = true;
//     });
//     return categoriesFromURL;
//   };

//   const [selectCategory, setSelectCategory] = useState(initializeCategories);

//   const allChecked =
//     productCategory.length === Object.keys(selectCategory).length &&
//     productCategory.every((cat) => selectCategory[cat.value]);

//   const toggleSidebar = () => setSidebarVisible(!sidebarVisible);

//   const handleClickOutside = (event) => {
//     if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
//       setSidebarVisible(false);
//     }
//   };

//   useEffect(() => {
//     if (sidebarVisible) {
//       document.addEventListener('mousedown', handleClickOutside);
//     } else {
//       document.removeEventListener('mousedown', handleClickOutside);
//     }
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [sidebarVisible]);

  
//   const fetchData = useCallback(async () => {
//     if (!localMarket || !filterCategoryList.length) {
//       setData([]); // Reset data if no market or category
//       setHasMore(false); // Stop further requests
//       return;
//     }
  
//     setLoading(true);

//     //const excludeIds = data.map(item => item._id); // Collect already fetched IDs
  
//     try {
//       const response = await fetch(summaryApi.filterProduct.url, {
//         method: summaryApi.filterProduct.method,
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           market: localMarket,
//           category: filterCategoryList,
//           page, // Make sure to send the current page number
//           sortBy,
//           //excludeIds,
//         }),
//       });
  
//       if (!response.ok) throw new Error(`Failed to fetch: ${response.statusText}`);
//       const dataResponse = await response.json();

   
//       if (Array.isArray(dataResponse?.data)) { // Check if data is an array
        
//         // Filter valid items and log any missing _id items
//         const validItems = dataResponse.data.filter(item => item && item._id);
  
//         validItems.forEach(item => {
//           if (!item || !item._id) {
//             console.warn('Item missing _id:', item);
//           }
//         });
  
//         // Update data state
//         setData(prevData => (page === 1 ? validItems : [...prevData, ...validItems]));
  
//         // Stop loading if fewer items are returned than the page size
//         if (validItems.length < pageSize) {
//           setHasMore(false);
//         }
  
//       } else {
//         console.error('Unexpected data format:', dataResponse.data);
//         setHasMore(false); // No more data if the response isn't an array
//         navigate('/error-fallback');
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       // Optionally navigate to the error fallback
//       // navigate('/error-fallback');
//     } finally {
//       setLoading(false); // Ensure loading is set to false
//     }
//   }, [localMarket, filterCategoryList, page, sortBy]);
  

//   const handleSelectCategory = (e) => {
//     const { value, checked } = e.target;
//     if (value === "All") {
//       const newSelectCategory = {};
//       productCategory.forEach((category) => (newSelectCategory[category.value] = checked));
//       setSelectCategory(newSelectCategory);
//     } else {
//       setSelectCategory((prev) => ({ ...prev, [value]: checked }));
//     }
//   };

//   useEffect(() => {
//     const arrayOfCategory = Object.keys(selectCategory).filter((categoryKey) => selectCategory[categoryKey]);
//     setFilterCategoryList(arrayOfCategory);

//     const params = new URLSearchParams();
//     params.set("market", localMarket);
//     arrayOfCategory.forEach((cat) => params.append("category", cat));
//     const newUrl = `/product-category?${params.toString()}`;
//     navigate(newUrl, { replace: true });
//   }, [selectCategory, localMarket]);

//   const handleOnChangeSortBy = (e) => {
//     const { value } = e.target;
//     setSortBy(value);
//     setData((prev) => [...prev].sort((a, b) => (value === 'asc' ? a.price - b.price : b.price - a.price)));
//   };

//   useEffect(() => {
//     setData([]); // Reset data on new filter
//     setPage(1); // Reset page
//     setHasMore(true);
//     fetchData();
//   }, [filterCategoryList, sortBy, localMarket]);

//   useEffect(() => {
//     if (page > 1) 
//       fetchData();
//   }, [page]);

//   const lastProductRef = useCallback(
//     (node) => {
//         if (loading || !hasMore) return; // Add check for hasMore
//         if (observerRef.current) observerRef.current.disconnect();
//         observerRef.current = new IntersectionObserver((entries) => {
//             if (entries[0].isIntersecting) {
//                 setPage((prevPage) => prevPage + 1);
//             }
//         });
//         if (node) observerRef.current.observe(node);
//     },
//     [loading, hasMore]
// );

  
//   return (
//         <div className="container customMin-300:max-w-full mx-auto p-4">
//           {/* Toggle button for sidebar on mobile */}
//           <button
//             className="custom-847:hidden mb-2 p-2 bg-gray-700 text-white rounded flex items-center mt-10"
//             onClick={toggleSidebar}
//           >
//             <IoMdMenu size={24} />
//             <span className="ml-2">{sidebarVisible ? "Close Menu" : "Filter & Sort"}</span>
//           </button>
    
//           <div className="grid grid-col-1 custom847-1023:grid-cols-[150px,1fr] lg:grid-cols-[200px,1fr] gap-4">
            
//             {/* Sidebar for sorting and filtering */}
//             <div
//               ref={sidebarRef}
//               className={`${sidebarVisible ? "block" : "hidden"} custom-847:block bg-white p-4 rounded-lg shadow-lg custom-847:min-h-[calc(100vh-120px)] custom-847:overflow-y-scroll fixed inset-y-0 left-0 custom-847:relative z-20`}
//             >
//               <div className='customMax-846:mt-[98px]'>
//                 <h3 className="text-base uppercase font-medium text-slate-500 border-b pb-2 mb-2 border-slate-300">
//                   Sort by
//                 </h3>
//                 <form className="text-sm flex flex-col gap-2">
//                   <div className="flex items-center gap-3">
//                     <input type="radio" name="sortBy" checked={sortBy === 'asc'} onChange={handleOnChangeSortBy} value="asc" />
//                     <label>Price - Low to High</label>
//                   </div>
//                   <div className="flex items-center gap-3">
//                     <input type="radio" name="sortBy" checked={sortBy === 'dsc'} onChange={handleOnChangeSortBy} value="dsc" />
//                     <label>Price - High to Low</label>
//                   </div>
//                 </form>
//               </div>
    
//               <div className="mt-4">
//                 <h3 className="text-base uppercase font-medium text-slate-500 border-b pb-2 mb-2 border-slate-300">
//                   Category
//                 </h3>
//                 <form className="text-sm flex flex-col gap-2">
//                   {productCategory.map((categoryName) => (
//                     <div className="flex items-center gap-3" key={categoryName.value}>
//                       <input
//                         type="checkbox"
//                         name="category"
//                         checked={categoryName.value === "All" ? allChecked : selectCategory[categoryName.value] || false}
//                         value={categoryName.value}
//                         id={categoryName.value}
//                         onChange={handleSelectCategory}
//                       />
//                       <label htmlFor={categoryName.value}>{categoryName.label}</label>
//                     </div>
//                   ))}
//                 </form>
//               </div>
//             </div>
    
//             {/* Product Display Section */}
//             <div className="md:px-4 flex flex-col">
//               <p className="font-medium text-slate-700 mb-2 text-lg">Total Product ({data.length})</p>
//               <div className="min-h-[calc(100vh-120px)] overflow-y-scroll max-h-[calc(100vh-120px)]">
//                 <AllVerticalProductCard
//                   loading={loading}
//                   data={data}
//                   localMarket={localMarket}
//                   ref={lastProductRef} // Pass this only to the last item if needed
//                 />
//                 {loading && <p>Loading...</p>}
//               </div>
//             </div>
//           </div>
//         </div>
//       )
//     }

//  export default CategoryProduct;


//this function is better than the above 
// import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { IoMdMenu } from "react-icons/io";
// import productCategory from '../helpers/ProductCategory';
// import AllVerticalProductCard from '../components/AllVerticalProductCard';
// import summaryApi from '../common';
// import { MarketContext } from '../context/MarketContext';

// const CategoryProduct = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [sidebarVisible, setSidebarVisible] = useState(false);
//   const [page, setPage] = useState(1);
//   const [hasMore, setHasMore] = useState(true);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { localMarket } = useContext(MarketContext);
//   const [filterCategoryList, setFilterCategoryList] = useState([]);
//   const [sortBy, setSortBy] = useState("");
//   const sidebarRef = useRef(null);
//   const observerRef = useRef();
//   const pageSize = 20; // Define your page size limit here

//   const urlSearch = new URLSearchParams(location.search);
//   const urlCategoryListinArray = urlSearch.getAll("category");

//   const initializeCategories = () => {
//     const categoriesFromURL = {};
//     urlCategoryListinArray.forEach((category) => {
//       categoriesFromURL[category] = true;
//     });
//     return categoriesFromURL;
//   };

//   const [selectCategory, setSelectCategory] = useState(initializeCategories);

//   const allChecked =
//     productCategory.length === Object.keys(selectCategory).length &&
//     productCategory.every((cat) => selectCategory[cat.value]);

//   const toggleSidebar = () => setSidebarVisible(!sidebarVisible);

//   const handleClickOutside = (event) => {
//     if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
//       setSidebarVisible(false);
//     }
//   };

//   useEffect(() => {
//     if (sidebarVisible) {
//       document.addEventListener('mousedown', handleClickOutside);
//     } else {
//       document.removeEventListener('mousedown', handleClickOutside);
//     }
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [sidebarVisible]);

  
//   const fetchData = useCallback(async () => {
//     if (!localMarket || !filterCategoryList.length) {
//       setData([]); // Reset data
//       setHasMore(false); // Stop further requests
//       return;
//     }
  
//     setLoading(true);
  
//     try {
//       const response = await fetch(summaryApi.filterProduct.url, {
//         method: summaryApi.filterProduct.method,
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           market: localMarket,
//           category: filterCategoryList,
//           page, // Use current page
//           sortBy, // Pass sortBy value to API
//         }),
//       });
  
//       if (!response.ok) throw new Error('Failed to fetch');
  
//       const dataResponse = await response.json();
//       const validItems = dataResponse.data.filter((item) => item && item._id);
  
//       // Update data state
//       setData((prevData) => (page === 1 ? validItems : [...prevData, ...validItems]));
  
//       // Stop loading if fewer items are returned than the page size
//       if (validItems.length < pageSize) {
//         setHasMore(false);
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setLoading(false);
//     }
//   }, [localMarket, filterCategoryList, page, sortBy]);
  

//   const handleSelectCategory = (e) => {
//     const { value, checked } = e.target;
//     let updatedCategories;
  
//     if (value === "All") {
//       updatedCategories = {};
//       productCategory.forEach((category) => (updatedCategories[category.value] = checked));
//     } else {
//       updatedCategories = { ...selectCategory, [value]: checked };
//     }
  
//     // Update selected categories
//     setSelectCategory(updatedCategories);
  
//     // Derive filtered category list
//     const selectedCategories = Object.keys(updatedCategories).filter((key) => updatedCategories[key]);
//     setFilterCategoryList(selectedCategories);
  
//     // Reset data, page, and hasMore
//     setData([]);
//     setPage(1);
//     setHasMore(true);
//   };
  
//   useEffect(() => {
//     const arrayOfCategory = Object.keys(selectCategory).filter((categoryKey) => selectCategory[categoryKey]);
//     setFilterCategoryList(arrayOfCategory);

//     const params = new URLSearchParams();
//     params.set("market", localMarket);
//     arrayOfCategory.forEach((cat) => params.append("category", cat));
//     const newUrl = `/product-category?${params.toString()}`;
//     navigate(newUrl, { replace: true });
//   }, [selectCategory, localMarket]);

//   const handleOnChangeSortBy = (e) => {
//     const { value } = e.target;
//     setSortBy(value); // Update sort order
  
//     // Reset data, page, and hasMore before fetching new data
//     setData([]);
//     setPage(1);
//     setHasMore(true);
//   };
  

//   useEffect(() => {
//     setData([]); // Reset data on new filter
//     setPage(1); // Reset page
//     setHasMore(true);
//     fetchData();
//   }, [filterCategoryList, sortBy, localMarket]);

//   useEffect(() => {
//     if (page > 1) 
//       fetchData();
//   }, [page]);

//   const lastProductRef = useCallback(
//     (node) => {
//         if (loading || !hasMore) return; // Add check for hasMore
//         if (observerRef.current) observerRef.current.disconnect();
//         observerRef.current = new IntersectionObserver((entries) => {
//             if (entries[0].isIntersecting) {
//                 setPage((prevPage) => prevPage + 1);
//             }
//         });
//         if (node) observerRef.current.observe(node);
//     },
//     [loading, hasMore]
// );

  
//   return (
//         <div className="container customMin-300:max-w-full mx-auto p-4">
//           {/* Toggle button for sidebar on mobile */}
//           <button
//             className="custom-847:hidden mb-2 p-2 bg-gray-700 text-white rounded flex items-center mt-10"
//             onClick={toggleSidebar}
//           >
//             <IoMdMenu size={24} />
//             <span className="ml-2">{sidebarVisible ? "Close Menu" : "Filter & Sort"}</span>
//           </button>
    
//           <div className="grid grid-col-1 custom847-1023:grid-cols-[150px,1fr] lg:grid-cols-[200px,1fr] gap-4">
            
//             {/* Sidebar for sorting and filtering */}
//             <div
//               ref={sidebarRef}
//               className={`${sidebarVisible ? "block" : "hidden"} custom-847:block bg-white p-4 rounded-lg shadow-lg custom-847:min-h-[calc(100vh-120px)] custom-847:overflow-y-scroll fixed inset-y-0 left-0 custom-847:relative z-20`}
//             >
//               <div className='customMax-846:mt-[98px]'>
//                 <h3 className="text-base uppercase font-medium text-slate-500 border-b pb-2 mb-2 border-slate-300">
//                   Sort by
//                 </h3>
//                 <form className="text-sm flex flex-col gap-2">
//                   <div className="flex items-center gap-3">
//                     <input type="radio" name="sortBy" checked={sortBy === 'asc'} onChange={handleOnChangeSortBy} value="asc" />
//                     <label>Price - Low to High</label>
//                   </div>
//                   <div className="flex items-center gap-3">
//                     <input type="radio" name="sortBy" checked={sortBy === 'dsc'} onChange={handleOnChangeSortBy} value="dsc" />
//                     <label>Price - High to Low</label>
//                   </div>
//                 </form>
//               </div>
    
//               <div className="mt-4">
//                 <h3 className="text-base uppercase font-medium text-slate-500 border-b pb-2 mb-2 border-slate-300">
//                   Category
//                 </h3>
//                 <form className="text-sm flex flex-col gap-2">
//                   {productCategory.map((categoryName) => (
//                     <div className="flex items-center gap-3" key={categoryName.value}>
//                       <input
//                         type="checkbox"
//                         name="category"
//                         checked={categoryName.value === "All" ? allChecked : selectCategory[categoryName.value] || false}
//                         value={categoryName.value}
//                         id={categoryName.value}
//                         onChange={handleSelectCategory}
//                       />
//                       <label htmlFor={categoryName.value}>{categoryName.label}</label>
//                     </div>
//                   ))}
//                 </form>
//               </div>
//             </div>
    
//             {/* Product Display Section */}
//             <div className="md:px-4 flex flex-col">
//               <p className="font-medium text-slate-700 mb-2 text-lg">Total Product ({data.length})</p>
//               <div className="min-h-[calc(100vh-120px)] overflow-y-scroll max-h-[calc(100vh-120px)]">
//                 <AllVerticalProductCard
//                   loading={loading}
//                   data={data}
//                   localMarket={localMarket}
//                   ref={lastProductRef} // Pass this only to the last item if needed
//                 />
//                 {loading && <p>Loading...</p>}
//               </div>
//             </div>
//           </div>
//         </div>
//       )
//     }

//  export default CategoryProduct;

import React, { useContext, useReducer, useEffect, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoMdMenu } from 'react-icons/io';
import productCategory from '../helpers/ProductCategory';
import AllVerticalProductCard from '../components/AllVerticalProductCard';
import summaryApi from '../common';
import { MarketContext } from '../context/MarketContext';
import { initialState, reducer, actionTypes } from '../store/ProductFilterSlice';

const CategoryProduct = () => {
  const { localMarket } = useContext(MarketContext);
  const location = useLocation();
  const navigate = useNavigate();
  const observerRef = useRef();
  const sidebarRef = useRef();
  const pageSize = 20;

  const [state, dispatch] = useReducer(reducer, initialState);

  const urlSearch = new URLSearchParams(location.search);
  const urlCategoryListinArray = urlSearch.getAll('category');
  const urlSortBy = urlSearch.get('sortBy') || 'asc';
  const urlMarket = urlSearch.get('market') || localMarket;

  // Ensure market is always synced to URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (!params.get('market')) {
      params.set('market', localMarket);
      navigate(`?${params.toString()}`, { replace: true });
    }
  }, [localMarket, location.search, navigate]);

  const initializeCategories = () => {
    const categoriesFromURL = {};
    urlCategoryListinArray.forEach((category) => {
      categoriesFromURL[category] = true;
    });
    return categoriesFromURL;
  };

  useEffect(() => {
    const initialCategories = initializeCategories();
    dispatch({ type: actionTypes.SET_SELECT_CATEGORY, payload: initialCategories });

    const selectedCategories = Object.keys(initialCategories).filter((key) => initialCategories[key]);
    dispatch({ type: actionTypes.SET_FILTER_CATEGORY, payload: selectedCategories });

    dispatch({ type: actionTypes.SET_SORT_BY, payload: urlSortBy });
    dispatch({ type: actionTypes.RESET_DATA });
  }, [location.search]);

  const fetchData = useCallback(async () => {
    if (!localMarket || state.filterCategoryList.length === 0) {
      dispatch({ type: actionTypes.RESET_DATA });
      return;
    }

    dispatch({ type: actionTypes.SET_LOADING, payload: true });

    try {
      const response = await fetch(summaryApi.filterProduct.url, {
        method: summaryApi.filterProduct.method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          market: localMarket,
          category: state.filterCategoryList,
          page: state.page,
          sortBy: state.sortBy,
        }),
      });

      if (!response.ok) throw new Error('Failed to fetch');

      const dataResponse = await response.json();
      const validItems = dataResponse.data.filter((item) => item && item._id);

      dispatch({ type: actionTypes.SET_DATA, payload: { data: validItems, page: state.page } });
      dispatch({ type: actionTypes.SET_HAS_MORE, payload: validItems.length >= pageSize });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      dispatch({ type: actionTypes.SET_LOADING, payload: false });
    }
  }, [localMarket, state.filterCategoryList, state.page, state.sortBy]);

  useEffect(() => {
    fetchData();
  }, [state.filterCategoryList, state.sortBy, state.page, localMarket]);

  const lastProductRef = useCallback(
    (node) => {
      if (state.loading || !state.hasMore) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          dispatch({ type: actionTypes.SET_PAGE, payload: state.page + 1 });
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [state.loading, state.hasMore, state.page]
  );

  const handleSortChange = (e) => {
    const sortBy = e.target.value;
    const params = new URLSearchParams(location.search);
    params.set('sortBy', sortBy);
    params.set('market', localMarket);
    state.filterCategoryList.forEach((cat) => params.append('category', cat));
    navigate(`?${params.toString()}`);
    dispatch({ type: actionTypes.RESET_DATA });
  };

  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    let updatedCategories = { ...state.selectCategory };

    if (value === 'All') {
      updatedCategories = {};
      productCategory.forEach((cat) => (updatedCategories[cat.value] = checked));
    } else {
      updatedCategories[value] = checked;
    }

    const selectedCategories = Object.keys(updatedCategories).filter((key) => updatedCategories[key]);

    dispatch({ type: actionTypes.SET_SELECT_CATEGORY, payload: updatedCategories });
    dispatch({ type: actionTypes.SET_FILTER_CATEGORY, payload: selectedCategories });

    const params = new URLSearchParams();
    params.set('market', localMarket);
    params.set('sortBy', state.sortBy);
    selectedCategories.forEach((cat) => params.append('category', cat));
    navigate(`?${params.toString()}`);

    dispatch({ type: actionTypes.RESET_DATA });
  };

  return (
    <div className="container customMin-300:max-w-full mx-auto p-4">
      <button className="custom-847:hidden mb-2 p-2 bg-gray-700 text-white rounded flex items-center mt-10" onClick={() => dispatch({ type: actionTypes.TOGGLE_SIDEBAR })}>
        <IoMdMenu size={24} />
        <span className="ml-2">{state.sidebarVisible ? "Close Menu" : "Filter & Sort"}</span>
      </button>

      <div className="grid grid-col-1 custom847-1023:grid-cols-[150px,1fr] lg:grid-cols-[200px,1fr] gap-4">
        <div ref={sidebarRef} className={`${state.sidebarVisible ? "block" : "hidden"} custom-847:block bg-white p-4 rounded-lg shadow-lg custom-847:min-h-[calc(100vh-120px)] custom-847:overflow-y-scroll fixed inset-y-0 left-0 custom-847:relative z-20`}>
          <div className='customMax-846:mt-[98px]'>
            <h3 className="text-base uppercase font-medium text-slate-500 border-b pb-2 mb-2 border-slate-300">Sort by</h3>
            <form className="text-sm flex flex-col gap-2">
              <div className="flex items-center gap-3">
                <input type="radio" name="sortBy" checked={state.sortBy === 'asc'} onChange={handleSortChange} value="asc" />
                <label>Price - Low to High</label>
              </div>
              <div className="flex items-center gap-3">
                <input type="radio" name="sortBy" checked={state.sortBy === 'dsc'} onChange={handleSortChange} value="dsc" />
                <label>Price - High to Low</label>
              </div>
            </form>
          </div>

          <div className='customMax-846:mt-[98px] mt-3'>
            <h3 className="text-base uppercase font-medium text-slate-500 border-b pb-2 mb-2 border-slate-300">Category</h3>
            <form className="text-sm flex flex-col gap-2">
              {productCategory.map((category) => (
                <div key={category.value} className="flex items-center gap-3">
                  <input
                    type="checkbox"
                    value={category.value}
                    checked={state.selectCategory[category.value] || false}
                    onChange={handleCategoryChange}
                  />
                  <label>{category.label}</label>
                </div>
              ))}
            </form>
          </div>
        </div>

        <div className="md:px-4 flex flex-col">
          <p className="font-medium text-slate-700 mb-2 text-lg">Total Product ({state.data.length})</p>
          <div className="min-h-[calc(100vh-120px)] overflow-y-scroll max-h-[calc(100vh-120px)]">
            <AllVerticalProductCard loading={state.loading} data={state.data} ref={lastProductRef} />
            {state.loading && <p>Loading...</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryProduct;













