// import React, { useState, useEffect } from "react";
// import summaryApi from "../../common";
// import { toast } from "react-toastify";

// const AdminFAQ = () => {
//   const [faqs, setFaqs] = useState([]);
//   const [newFAQ, setNewFAQ] = useState({ question: "", answer: "" });

//   useEffect(() => {
//     const fetchFAQs = async () => {
//       try {
//         const response = await fetch(summaryApi.getFaqs.url, {
//           method: summaryApi.getFaqs.method,
//           credentials: "include",
//           headers: {
//             "Content-Type": "application/json",
//           },
//         });

//         const responseData = await response.json();

//         if (responseData.success) {
//           setFaqs(responseData.data);
//         } else {
//           toast.error(responseData.message);
//         }
//       } catch (error) {
//         console.error("Error fetching FAQs:", error);
//       }
//     };
//     fetchFAQs();
//   }, []);

//   const handleAddFAQ = async () => {
//     try {
//       const response = await fetch(summaryApi.createFaqs.url, {
//         method: summaryApi.createFaqs.method,
//         credentials: "include",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(newFAQ),
//       });

//       const responseData = await response.json();

//       if (responseData.success) {
//         toast.success(responseData.message);
//         setFaqs([...faqs, responseData.data]);
//         setNewFAQ({ question: "", answer: "" });
//       } else {
//         toast.error(responseData.message);
//       }
//     } catch (error) {
//       console.error("Error adding FAQ:", error);
//     }
//   };

//   const handleDeleteFAQ = async (id) => {
//     try {
//       const response = await fetch(`${summaryApi.deleteFaqs.url}${id}`, {
//         method: summaryApi.deleteFaqs.method,
//         credentials: "include",
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       const responseData = await response.json();

//       if (responseData.success) {
//         setFaqs(faqs.filter((faq) => faq._id !== id));
//         toast.success("FAQ deleted successfully");
//       } else {
//         toast.error(responseData.message);
//       }
//     } catch (error) {
//       console.error("Error deleting FAQ:", error);
//     }
//   };

//   const handleEditFAQ = async (id, updatedFAQ) => {
//     try {
//       const response = await fetch(`${summaryApi.updateFaqs.url}${id}`, {
//         method: summaryApi.updateFaqs.method,
//         credentials: "include",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(updatedFAQ),
//       });

//       const responseData = await response.json();

//       if (responseData.success) {
//         setFaqs(faqs.map((faq) => (faq._id === id ? responseData.data : faq)));
//         toast.success("FAQ updated successfully");
//       } else {
//         toast.error(responseData.message);
//       }
//     } catch (error) {
//       console.error("Error editing FAQ:", error);
//     }
//   };

//   return (
//     <div>
//       <h1>Manage FAQs</h1>
//       <div>
//         <input
//           type="text"
//           placeholder="Question"
//           value={newFAQ.question}
//           onChange={(e) => setNewFAQ({ ...newFAQ, question: e.target.value })}
//         />
//         <textarea
//           placeholder="Answer"
//           value={newFAQ.answer}
//           onChange={(e) => setNewFAQ({ ...newFAQ, answer: e.target.value })}
//         />
//         <button onClick={handleAddFAQ}>Add FAQ</button>
//       </div>
//       <ul>
//         {faqs.map((faq) => (
//           <li key={faq._id}>
//             <h2>{faq.question}</h2>
//             <p>{faq.answer}</p>
//             <button onClick={() => handleDeleteFAQ(faq._id)}>Delete</button>
//             <button
//               onClick={() =>
//                 handleEditFAQ(faq._id, {
//                   question: "Updated Question",
//                   answer: "Updated Answer",
//                 })
//               }
//             >
//               Edit
//             </button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default AdminFAQ;

import React, { useState, useEffect } from "react";
import summaryApi from "../../common";
import { toast } from "react-toastify";

const AdminFAQ = () => {
  const [faqs, setFaqs] = useState([]);
  const [newFAQ, setNewFAQ] = useState({ question: "", answer: "" });
  const [editFAQId, setEditFAQId] = useState(null); // Track the editing FAQ
  const [editData, setEditData] = useState({ question: "", answer: "" }); // Store edited values


  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const response = await fetch(summaryApi.getFaqs.url, {
          method: summaryApi.getFaqs.method,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const responseData = await response.json();

        if (responseData.success) {
          setFaqs(responseData.data);
        } else {
          toast.error(responseData.message);
        }
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };
    fetchFAQs();
  }, []);

  const handleAddFAQ = async () => {
    try {
      const response = await fetch(summaryApi.createFaqs.url, {
        method: summaryApi.createFaqs.method,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newFAQ),
      });

      const responseData = await response.json();

      if (responseData.success) {
        toast.success(responseData.message);
        setFaqs([...faqs, responseData.data]);
        setNewFAQ({ question: "", answer: "" });
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error("Error adding FAQ:", error);
    }
  };

  const handleDeleteFAQ = async (id) => {
    try {
        const response = await fetch(`${summaryApi.DeleteFaqs.url}/${id}`, { // Add '/' before ${id}
            method: summaryApi.DeleteFaqs.method,
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(errorText);
        }

        const responseData = await response.json();

        if (responseData.success) {
            setFaqs(faqs.filter((faq) => faq._id !== id));
            toast.success("FAQ deleted successfully");
        } else {
            toast.error(responseData.message);
        }
    } catch (error) {
        console.error("Error deleting FAQ:", error);
    }
};


  

const handleSaveEdit = async () => {
    try {
      const response = await fetch(`${summaryApi.updateFaqs.url}/${editFAQId}`, {
        method: summaryApi.updateFaqs.method,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editData),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }
  
      const responseData = await response.json();
  
      if (responseData.success) {
        setFaqs((faqs) =>
          faqs.map((faq) => (faq._id === editFAQId ? responseData.data : faq))
        );
        toast.success("FAQ updated successfully");
        setEditFAQId(null); // Exit edit mode
        setEditData({ question: "", answer: "" });
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      console.error("Error saving FAQ:", error);
      toast.error("Failed to save FAQ");
    }
  };
  

// Call handleEditFAQ when the edit button is clicked
    const handleEditClick = (id) => {
        const faqToEdit = faqs.find((faq) => faq._id === id);
        setEditFAQId(id); // Set the ID of the FAQ being edited
        setEditData({ question: faqToEdit.question, answer: faqToEdit.answer }); // Load current data
    };
  

  return (
    <div className="bg-black min-h-screen text-white p-6">
      <h1 className="text-3xl font-bold text-orange-500 mb-6">Manage FAQs</h1>
      <div className="bg-gray-900 p-6 rounded-lg shadow-lg mb-6">
        <h2 className="text-xl font-semibold text-orange-400 mb-4">
          Add a New FAQ
        </h2>
        <input
          type="text"
          placeholder="Question"
          value={newFAQ.question}
          onChange={(e) => setNewFAQ({ ...newFAQ, question: e.target.value })}
          className="w-full p-3 mb-4 bg-gray-800 text-white border border-gray-700 rounded focus:outline-none focus:border-orange-500"
        />
        <textarea
          placeholder="Answer"
          value={newFAQ.answer}
          onChange={(e) => setNewFAQ({ ...newFAQ, answer: e.target.value })}
          className="w-full p-3 mb-4 bg-gray-800 text-white border border-gray-700 rounded focus:outline-none focus:border-orange-500"
          rows="4"
        />
        <button
          onClick={handleAddFAQ}
          className="bg-orange-500 text-white px-6 py-2 rounded hover:bg-orange-600 transition"
        >
          Add FAQ
        </button>
      </div>
        <ul className="space-y-4">
            {faqs.map((faq) => (
                <li key={faq._id} className="bg-gray-900 p-6 rounded-lg shadow-lg">
                {editFAQId === faq._id ? (
                    // Render edit container
                    <div>
                    <input
                        type="text"
                        value={editData.question}
                        onChange={(e) => setEditData({ ...editData, question: e.target.value })}
                        className="w-full p-3 mb-4 bg-gray-800 text-white border border-gray-700 rounded focus:outline-none focus:border-orange-500"
                    />
                    <textarea
                        value={editData.answer}
                        onChange={(e) => setEditData({ ...editData, answer: e.target.value })}
                        className="w-full p-3 mb-4 bg-gray-800 text-white border border-gray-700 rounded focus:outline-none focus:border-orange-500"
                        rows="4"
                    />
                    <button
                        onClick={handleSaveEdit}
                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
                    >
                        Save
                    </button>
                    <button
                        onClick={() => setEditFAQId(null)}
                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition ml-2"
                    >
                        Cancel
                    </button>
                    </div>
                ) : (
                    // Render normal view
                    <>
                    <h2 className="text-lg font-semibold text-orange-400 mb-2">{faq.question}</h2>
                    <p className="text-gray-300 mb-4">{faq.answer}</p>
                    <div className="flex space-x-4">
                        <button
                        onClick={() => handleDeleteFAQ(faq._id)}
                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
                        >
                        Delete
                        </button>
                        <button
                        onClick={() => handleEditClick(faq._id)}
                        className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 transition"
                        >
                        Edit
                        </button>
                    </div>
                    </>
                )}
                </li>
            ))}
        </ul>

    </div>
  );
};

export default AdminFAQ;
