import React from 'react'

function Footer() {
  return (
    <div className='w-full w-max-full p-6 bg-slate-900 mt-2'>
      <div className='grid grid-cols-4 gap-4 p-4 text-white w-full custom-550-grid:grid-cols-2 custom-551-950-grid:grid-cols-3'>
        
      <div>
          <h2 className='mb-4 font-bold'>About Us</h2>
          <ul className="list-none">
            <a href='privacy-policy'><li>Privacy policy</li></a>
            <a href='terms-of-service'><li>Terms of service</li></a>
            <a href='signup'><li>Register as Seller</li></a>
            <li></li>
          </ul>
        </div>
        
        <div>
          <h2 className='mb-4 font-bold'>How to make money with us</h2>
          <ul className="list-none">
            <a href='seller-onboarding'><li>Sell on Homemarket2U </li></a>
            <a href='about-affiliate-marketing'><li>About Affiliate Marketing</li></a>
            <a href='learn-about-dropshipping'><li>Learn About Drop Shipping</li></a>
          </ul>
        </div>

        <div>
          <h2 className='mb-4 font-bold'>Logistics and Transportation</h2>
          <ul className="list-none">
            <a href='logistic-info'><li>Our Logistics</li></a>
            <a href='learn-packaging'><li>Tips for product packaging</li></a>
            <a href='free-product-preservation'><li>Tips on vegetable and fresh preservation</li></a>
            
          </ul>
        </div>

        <div>
          <h2 className='mb-4 font-bold'>About HomeMarket2U Freelance</h2>
          <ul className="list-none">
            <a href='about-freelancing'><li>About Freelance</li></a>
            <a href='make-money-freelancer'><li>How to make money as freelancer</li></a>
            <a href='tips-to-succeed-as-freelancer'><li>Tip to succeed as freelancer</li></a>
          </ul>
        </div>

      </div>
    </div>
  )
}

export default Footer