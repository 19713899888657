import React, { useState, useEffect, useContext } from 'react';
import Context from '../../context';
import summaryApi from '../../common';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ServiceCategory from '../../helpers/ServiceCategory';
import subCategories from '../../helpers/ServiceSubCategory';
import { MdDelete } from 'react-icons/md';
import DisplayImage from '../../components/DisplayImage';

const FreelancerUpdateAccount = () => {
  const [formData, setFormData] = useState({
    profilePic: '',
    wallPicture: '',
    workTitle: '',
    categories: [],
    subcategories: [],
    skills: '',
    experiences: [],
    pastProjects: [],
    references: '',
    education: '',
    qualifications: '',
    tools: '',
    currency: "",
    hourlyRate: '',
    weeklyRate: '',
    monthlyRate: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { fetchUserdetails, fetchFreelancerdetails } = useContext(Context);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategories, setSelectedSubcategories] = useState();
  const [fullScreeImage, setFullScreenImage] = useState("")
  const [openFullScreenImage, setOpenFullScreenImage] = useState(false)
  const navigate = useNavigate();

  // Fetch existing seller data when the component mounts
  useEffect(() => {
    const fetchCurrentUserDetails = async () => {
        try {
          const userDetails = await fetchUserdetails();
          if (userDetails) {
           
            setFormData((prevState) => ({ ...prevState, ...userDetails }));
          } else {
            console.error('User details are undefined or null');
          }

          if (!userDetails || typeof userDetails !== 'object') {
            console.warn('Invalid userDetails data:', userDetails);
            return;
          }
        } catch (error) {
          console.error('Error fetching seller details:', error);
        }
      };

      const fetchFreelancerData = async () => {
        try {
          const userDetails = await fetchFreelancerdetails();
          if (userDetails) {
            
            setFormData((prevState) => ({ ...prevState, ...userDetails }));
          } else {
            console.error('Freelancer User details are undefined or null');
          }

          if (!userDetails || typeof userDetails !== 'object') {
            console.warn('Invalid userDetails data:', userDetails);
            return;
          }
        } catch (error) {
          console.error('Error fetching seller details:', error);
        }
      };
      
      fetchFreelancerData();
      fetchCurrentUserDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };




  // const handleUploadProfilePic = async (e) => {
  //   const file = e.target.files[0];
  //   const formData = new FormData();
  //   formData.append("files", file);
  //   formData.append("type", "profilePic"); // Specify the file type
  
  //   try {
  //     const response = await fetch(summaryApi.imageUpload.url, {
  //       method: summaryApi.imageUpload.method,
  //       body: formData,
  //     });
  
  //     if (!response.ok) {
  //       throw new Error("Failed to upload profile picture");
  //     }
  
  //     const { imageUrls } = await response.json();
  //     setFormData((prev) => ({ ...prev, profilePic: imageUrls[0] }));
  //   } catch (error) {
  //     console.error("Error uploading profile picture:", error.message);
  //   }
  // };
  

  // const handleUploadWallPicture = async (e) => {
  //   const file = e.target.files[0];
  //   const formData = new FormData();
  //   formData.append("files", file);
  //   formData.append("type", "wallPicture"); // Specify the file type
  
  //   try {
  //     const response = await fetch(summaryApi.imageUpload.url, {
  //       method: summaryApi.imageUpload.method,
  //       body: formData,
  //     });
  
  //     if (!response.ok) {
  //       throw new Error("Failed to upload wall picture");
  //     }
  
  //     const { imageUrls } = await response.json();
  //     setFormData((prev) => ({ ...prev, wallPicture: imageUrls[0] }));
  //   } catch (error) {
  //     console.error("Error uploading wall picture:", error.message);
  //   }
  // };

  // const handleUploadPastProjects = async (e) => {
  //   const file = e.target.files[0];
  //   const formData = new FormData();
  //   formData.append("files", file);
  //   formData.append("type", "pastProjects"); // Specify the file type
  
  //   try {
  //     const response = await fetch(summaryApi.imageUpload.url, {
  //       method: summaryApi.imageUpload.method,
  //       body: formData,
  //     });
  
  //     if (!response.ok) {
  //       throw new Error("Failed to upload profile picture");
  //     }
  
  //     const { imageUrls } = await response.json();
  //     setFormData((prev) => ({ ...prev, pastProjects: imageUrls[0] }));
  //   } catch (error) {
  //     console.error("Error uploading profile picture:", error.message);
  //   }
  // };

  const handleUpload = async (e, type) => {
    const files = e.target.files; // Allow for multiple file uploads
    const formData = new FormData();
  
    Array.from(files).forEach((file) => {
      formData.append("files", file);
    });
    formData.append("type", type); // Specify the type of upload
  
    try {
      const response = await fetch(summaryApi.imageUpload.url, {
        method: summaryApi.imageUpload.method,
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`Failed to upload ${type}`);
      }
  
      const { imageUrls } = await response.json();
  
      setFormData((prev) => {
        const updatedField = type === "pastProjects"
          ? [...prev.pastProjects, ...imageUrls] // Append new files to the existing array
          : imageUrls[0]; // Single file for `profilePic` or `wallPicture`
  
        return { ...prev, [type]: updatedField };
      });
    } catch (error) {
      console.error(`Error uploading ${type}:`, error.message);
    }
  };
  

  const handleProfilePictureSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(summaryApi.updateUserAccount.url, {
        method: summaryApi.updateUserAccount.method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ profilePic: formData.profilePic }),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        toast.success('Profile picture updated successfully!');
        await fetchUserdetails();
      } else {
        toast.error(result.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error updating profile picture:', error);
      toast.error('An unexpected error occurred');
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const formDataCopy = { ...formData };
  
      // Convert files to strings or remove them if unchanged
      // ['profilePic', 'wallPicture', 'pastProjects'].forEach((fileField) => {
      //   if (formData[fileField] instanceof File) {
      //     // Process the file upload (e.g., upload to a server or cloud storage)
      //     // Example: Replace with the file's URL after upload
      //     formDataCopy[fileField] = 'imageUrls';
      //   } else if (typeof formData[fileField] !== 'string') {
      //     delete formDataCopy[fileField];
      //   }
      // });

     
      
  
      const response = await fetch(summaryApi.updateFreelancerAccount.url, {
        method: summaryApi.updateFreelancerAccount.method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataCopy),
      });
  
      const result = await response.json();
      if (response.ok) {
        toast.success('Profile updated successfully!');
        await fetchUserdetails();
        navigate('/freelancer');
      } else {
        toast.error(result.message || 'An error occurred');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('An unexpected error occurred');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCategoryChange = (e) => {
    const category = e.target.value;

    setSelectedCategory(category);

    // Initialize subcategories for the new category if not already present
    setSelectedSubcategories((prev) => ({
      ...prev,
      [setFormData.categories]: prev[category] || [],
    }));
  };

  const handleSubcategoryChange = (e) => {
    const { value, checked } = e.target;

    setSelectedSubcategories((prev) => {
      const currentSubs = prev[selectedCategory] || [];
      const updatedSubs = checked
        ? [...currentSubs, value]
        : currentSubs.filter((sub) => sub !== value);

      return { ...prev, [selectedCategory]: updatedSubs };
    });

    setFormData((prevData) => {
      // Update `formData.subcategories` with all selected subcategories from all categories
      const updatedAllSubcategories = Object.values({
        ...selectedSubcategories,
        [selectedCategory]: checked
          ? [...(selectedSubcategories[selectedCategory] || []), value]
          : (selectedSubcategories[selectedCategory] || []).filter(
              (sub) => sub !== value
            ),
      }).flat();

      return { ...prevData, subcategories: updatedAllSubcategories };
    });
  };


  const handleDeleteProductImage = async(index) =>{
    const newProductImage = [...formData?.pastProjects]
    newProductImage.splice(index,1)

    setFormData((preve) => {
     return {
         ...preve,
         productImage : [...newProductImage]
     }
    })
 }

  return (
    <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-6">
      <h2 className="text-orange-600 text-2xl font-bold text-center mb-6">Update Your Profile</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Profile Picture</label>
          <input
            type="file"
            name="profilePic"
            onChange={(e) => handleUpload(e, "profilePic")}
            className="block w-full text-sm text-blue-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-orange-100 file:text-orange-600 hover:file:bg-orange-200"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Wall Picture</label>
          <input
            type="file"
            name="wallPicture"
            
            onChange={(e) => handleUpload(e, "wallPicture")}
            className="block w-full text-sm text-blue-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-orange-100 file:text-orange-600 hover:file:bg-orange-200"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Work Title</label>
          <input
            type="text"
            name="workTitle"
            value={formData.workTitle || ''}
            onChange={handleChange}
            className="w-full border-blue-300 p-1 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
          />
        </div>

     {/* Category Selection */}
     <div>
          <label className="block font-medium mb-2">Select Category:</label>
          <select
            name="category"
            value={selectedCategory}
            onChange={handleCategoryChange}
            className="w-full border-blue-300 p-2 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
          >
            <option value="">Choose a category</option>
            {ServiceCategory.map((category) => (
              <option key={category.id} value={category.value}>
                {category.label}
              </option>
            ))}
          </select>
        </div>

        {/* Subcategory Selection */}
        {selectedCategory && (
          <div>
            <label className="block font-medium mb-2 mt-4">Select Subcategories:</label>
            <div className="grid grid-cols-2 gap-2">
              {subCategories[selectedCategory]?.map((subCategory) => (
                <div key={subCategory} className="flex items-center">
                  <input
                    type="checkbox"
                    id={subCategory}
                    name="subcategories"
                    value={subCategory}
                    checked={selectedSubcategories[selectedCategory]?.includes(
                      subCategory
                    )}
                    onChange={handleSubcategoryChange}
                    className="w-4 h-4 mr-2"
                  />
                  <label htmlFor={subCategory} className="text-gray-700">
                    {subCategory}
                  </label>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Display Selected Subcategories */}
        <div className="mt-4">
          <label className="block font-medium mb-2">Selected Subcategories:</label>
          <div className="flex flex-wrap gap-2">
            {formData.subcategories.map((subcategory) => (
              <div
                key={subcategory}
                className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full border border-blue-300 text-sm"
              >
                {subcategory}
              </div>
            ))}
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Skills</label>
          <textarea
            name="skills"
            value={formData.skills || ''}
            onChange={handleChange}
            placeholder="E.g., JavaScript, React, Node.js"
            className="w-full p-2 border-blue-300 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
          ></textarea>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Experiences and projects</label>
          <textarea
            name="experiences"
            value={formData.experiences || ''}
            onChange={handleChange}
            className="w-full p-2 border-blue-300 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
          ></textarea>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Completed Projects</label>
          <input
            type="file"
            name="pastProjects"
            onChange={(e) => handleUpload(e, "pastProjects")}
            multiple
            className="block w-full text-sm text-blue-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-orange-100 file:text-orange-600 hover:file:bg-orange-200"
          />
            {
              formData?.pastProjects[0] ? (
                <div className='flex items-center gap-3 mt-3'>
                    
                    { formData?.pastProjects.map((el)=>{
                            return (
                                <div key={el} className='relative group'>
                                    <img src={el} 
                                        alt={el} 
                                        width={80} 
                                        height={80} 
                                        className='bg-slate-100 border cursor-pointer'
                                        required
                                        onClick={() => {
                                        setOpenFullScreenImage(true)
                                        setFullScreenImage(el)
                                    }} />

                                    <div className='bg-orange-500 text-white rounded-full cursor-pointer absolute bottom-0 right-0 p-1 hidden group-hover:block' onClick={() => handleDeleteProductImage(el)}> 
                                        <MdDelete /> 
                                    </div>
                                </div>
                                
                                
                            )
                    })}
                </div>
            )
            
            : 
            (
                <p className='text-red-600 text-xs'> *please upload product image* </p>
            )
        }
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">References</label>
          <textarea
            name="references"
            value={formData.references || ''}
            onChange={handleChange}
            className="w-full p-2 border-blue-300 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
          ></textarea>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Education</label>
          <textarea
            name="education"
            value={formData.education || ''}
            onChange={handleChange}
            className="w-full p-2 border-blue-300 border  rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
          ></textarea>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Qualification</label>
          <textarea
            name="qualifications"
            value={formData.qualifications || ''}
            onChange={handleChange}
            className="w-full p-2 border-blue-300 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
          ></textarea>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Tools</label>
          <textarea
            name="tools"
            value={formData.tools || ''}
            onChange={handleChange}
            className="w-full p-2 border-blue-300 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
          ></textarea>
        </div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Hourly Rate</label>
        <div className='flex item-center'>
          <select
            name='currency'
            value={formData.currency}
            onChange={handleChange}
            className='p-2 bg-slate-100 border rounded mr-2'
            required
        >
            <option value='USD'>$ (USD)</option>
            <option value='INR'>₹ (INR)</option>
            <option value='NGN'>₦ (NGN)</option>
            
          </select>
          <input
            type="number"
            name="hourlyRate"
            value={formData.hourlyRate || ''}
            onChange={handleChange}
            className=" border-blue-300 p-2 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
          />
        </div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Weekly Rate</label>
        <div className='flex items-center'>
          <select
            name='currency'
            value={formData.currency}
            onChange={handleChange}
            className='p-2 bg-slate-100 border rounded mr-2'
            required
        >
            <option value='USD'>$ (USD)</option>
            <option value='INR'>₹ (INR)</option>
            <option value='NGN'>₦ (NGN)</option>
            
          </select>
          <input
            type="number"
            name="weeklyRate"
            value={formData.weeklyRate || ''}
            onChange={handleChange}
            className=" border-blue-300 p-2 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
          />
        </div>

        <label className="block text-sm font-medium text-gray-700 mb-1">Monthly Rate</label>
        <div>
        <select
            name='currency'
            value={formData.currency}
            onChange={handleChange}
            className='p-2 bg-slate-100 border rounded mr-2'
            required
        >
            <option value='USD'>$ (USD)</option>
            <option value='INR'>₹ (INR)</option>
            <option value='NGN'>₦ (NGN)</option>
            
          </select>
          <input
            type="number"
            name="monthlyRate"
            value={formData.monthlyRate || ''}
            onChange={handleChange}
            className=" border-blue-300 p-2 border rounded-md shadow-sm focus:border-orange-500 focus:ring-orange-500"
          />
        </div>
        <button
          type="submit"
          disabled={isSubmitting}
          className={`w-full text-white font-semibold py-2 px-4 rounded-md shadow-sm ${
            isSubmitting
              ? 'bg-orange-400 cursor-not-allowed'
              : 'bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2'
          }`}
        >
          {isSubmitting ? 'Updating...' : 'Update Profile'}
        </button>
      </form>

         {/**display image full screen */}
      {  openFullScreenImage && (
      <DisplayImage 
      onClose={()=> setOpenFullScreenImage(false)} 
      imgUrl={fullScreeImage}/>
      )}
    </div>

  );
  
};

export default FreelancerUpdateAccount;