import React from 'react';

const SellerDialog = ({ isOpen, onClose, onRedirect }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md scroll-y  ">
        <h2 className="text-xl font-semibold mb-0 ">
          Learn How to Take Better Product Photos
        </h2>
        <p className="text-gray-700 mb-6">
          Watch our recommended tutorial on how to take professional pictures for your eCommerce store!
        </p>
        <div className="flex gap-4 justify-center">
          <button
            onClick={onRedirect}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-all"
          >
            Watch Now
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition-all"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SellerDialog;
