import React from "react";

const AboutFreelancing = () => {
  return (
    <div className="max-w-4xl mx-auto px-6 py-12 font-sans">
      <h1 className="text-center text-3xl font-bold mb-6">About Freelancing</h1>
      <p>
        Freelancing is a form of self-employment where individuals offer their
        skills and expertise to clients on a project or task basis. Freelancers
        work independently, often remotely, and have the flexibility to choose
        their projects, clients, and schedules.
      </p>
    </div>
  );
};

const MakeMoneyAsFreelancer = () => {
  return (
    <div className="max-w-4xl mx-auto px-6 py-12 font-sans">
      <h1 className="text-center text-3xl font-bold mb-6">How to Make Money as a Freelancer</h1>
      <ul className="list-disc list-inside mt-4">
        <li>
          <strong>Identify Your Skills:</strong> Focus on skills that are in demand
          and align with your expertise.
        </li>
        <li>
          <strong>Create a Portfolio:</strong> Showcase your work to potential
          clients with a professional portfolio or website.
        </li>
        <li>
          <strong>Use Freelance Platforms:</strong> Join platforms like Upwork,
          Fiverr, or Freelancer to find clients and projects.
        </li>
        <li>
          <strong>Network:</strong> Build connections within your industry to gain
          referrals and opportunities.
        </li>
      </ul>
    </div>
  );
};

const TipToSucceedAsFreelancer = () => {
  return (
    <div className="max-w-4xl mx-auto px-6 py-12 font-sans">
      <h1 className="text-center text-3xl font-bold mb-6">Tips to Succeed as a Freelancer</h1>
      <ul className="list-disc list-inside mt-4">
        <li>
          <strong>Time Management:</strong> Set clear schedules and deadlines to
          manage multiple projects effectively.
        </li>
        <li>
          <strong>Communication:</strong> Keep clients informed and updated on
          project progress to build trust.
        </li>
        <li>
          <strong>Continuous Learning:</strong> Update your skills regularly to
          stay competitive in your field.
        </li>
        <li>
          <strong>Deliver Quality:</strong> Focus on providing excellent work to
          maintain a good reputation.
        </li>
      </ul>
    </div>
  );
};

export {
  AboutFreelancing,
  MakeMoneyAsFreelancer,
  TipToSucceedAsFreelancer
};
