import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import summaryApi from "../../common";
import { toast } from "react-toastify";
import ExpandableRow from "../../components/updatePurchaseProductCard";

const SoldProducts = () => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [statusFilter, setStatusFilter] = useState("all"); // For filtering status
  const navigate = useNavigate();
  const limit = 25;

  const fetchSoldProducts = async (currentPage = 1, status = "all") => {
    try {
      const queryParams = new URLSearchParams({
        page: currentPage,
        limit: limit,
        ...(status !== "all" && { status }),
      });

      const url = `${summaryApi.getPurchases.url}?${queryParams.toString()}`;

      const response = await fetch(url, {
        method: summaryApi.getPurchases.method,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const dataResponse = await response.json();
      if (dataResponse.success) {
        setProducts(dataResponse?.data || []);
        setTotalPages(dataResponse.pagination?.totalPages || 1);
      } else if (dataResponse.status >= 500) {
        navigate("/error-fallback");
      } else {
        toast.error(dataResponse.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      navigate("/error-fallback");
    }
  };

  useEffect(() => {
    fetchSoldProducts(page, statusFilter);
  }, [page, statusFilter]);

  const handleStatusUpdate = (purchaseId, newStatus) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product._id === purchaseId ? { ...product, status: newStatus } : product
      )
    );
  };

  // Handle pagination
  const handleNextPage = () => {
    if (page < totalPages - 1) setPage(page + 1);
  };

  const handlePrevPage = () => {
    if (page > 0) setPage(page - 1);
  };

  // Handle status filter
  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
    setPage(0); // Reset to first page on filter change
  };

  return (
    <div>
      {/* Header */}
      <div className="bg-white flex justify-between py-2 px-4 items-center">
        <h2 className="font-bold text-lg">Sold Products</h2>
        <select
          value={statusFilter}
          onChange={handleStatusFilterChange}
          className="border rounded py-2 px-3"
        >
          <option value="all">All</option>
          <option value="pending">Pending</option>
          <option value="dispatched">Dispatched</option>
          <option value="delivered">Delivered</option>
        </select>
      </div>

      {/* Products Table */}
      <div className="px-4 overflow-auto">
        <table className="table-auto w-full text-left border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border px-4 py-2">Product Image</th>
              <th className="border px-4 py-2">Product Name</th>
              <th className="border px-4 py-2">Purchase ID / Product ID</th>
              <th className="border px-4 py-2">Buyer Name</th>
              <th className="border px-4 py-2">Buyer Address</th>
              <th className="border px-4 py-2">Seller Mobile</th>
              <th className="border px-4 py-2">Status</th>
            </tr>
          </thead>
          <tbody>
          {products.map((product) => (
              <ExpandableRow
                key={product._id}
                product={product}
                onStatusUpdate={handleStatusUpdate}
              />
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center gap-4 py-4 items-center">
        <button
          onClick={handlePrevPage}
          disabled={page === 0}
          className={`py-2 px-4 rounded ${page === 0 ? "bg-gray-200" : "bg-orange-500 text-white"}`}
        >
          Prev
        </button>
        <span>
          Page {page + 1} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={page === totalPages - 1}
          className={`py-2 px-4 rounded ${
            page === totalPages - 1 ? "bg-gray-200" : "bg-orange-500 text-white"
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SoldProducts