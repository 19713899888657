import React, { useEffect, useRef, useState } from 'react'
import summaryApi from '../common'
import { Link } from 'react-router-dom'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6'

export const CategoryList = ({ localMarket }) => {
    const [loading, setLoading] = useState(false)
    const [categoryProduct, setCategoryProduct] = useState([])
    const scrollElement = useRef();

    const categoryLoading = new Array(13).fill(null)

    const fetchCategoryList = async () => {
        if (!localMarket) return; // Ensure localMarket is available
    
        setLoading(true);
        try {
          const response = await fetch(summaryApi.getCategoryProduct.url, {
            method: summaryApi.getCategoryProduct.method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ localMarket }), // Pass the local market
          });
    
          if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
          }
    
          const dataResponse = await response.json();
          setCategoryProduct(dataResponse.data || []); // Ensure data is structured correctly
        } catch (error) {
          console.error('Error fetching category list:', error);
        } finally {
          setLoading(false);
        }
      };
    
      useEffect(() => {
        fetchCategoryList(); // Fetch data only when localMarket changes
      }, [localMarket]);


    const scrollRight = () => {
        scrollElement.current.scrollLeft += 300;
    };

    const scrollLeft = () => {
        scrollElement.current.scrollLeft -= 300;
    };


  return (
    <div className='container customMin-300:max-w-full mt-6 customMax-800:mt-4 w-full max-w-full pb-1  '>
        <div className='flex max-w-full items-center gap-8 justify-between bg-slate-900 p-5 '>
                <button
                    className='bg-white shadow-md rounded-full p-5 absolute left-0 text-lg hidden md:block z-10'
                    onClick={scrollLeft}
                    aria-label="Scroll left"
                >
                    <FaAngleLeft />
                </button>

                <button
                    className='bg-white shadow-md rounded-full p-5 absolute right-0 hidden md:block z-10'
                    onClick={scrollRight}
                    aria-label="Scroll right"
                >
                    <FaAngleRight />
                </button>

                <div ref={scrollElement} // This should be on the container that holds all the categories
                    className='flex items-center gap-3 justify-between overflow-x-auto scrollbar-hidden scrollbar-none'
                    style={{ scrollBehavior: 'smooth' }} // Smooth scrolling
                >
                    {
                        loading ? (
                            categoryLoading.map((el, index) => {
                                return (
                                    <div className='w-full h-full rounded overflow-hidden gap-4 md:gap-6 bg-slate-200 animate-pulse' key={"categoryLoading" + index}>
                                         <div className='flex items-center justify-between h-auto md:w-28 md:h-auto w-full mt-2' >
                                                {/* Container for the image with fixed height */}
                                                <div className='w-16 h-16 md:w-20 md:h-28 rounded overflow-hidden p-3 bg-slate-200 ma:w-full flex items-center justify-center'>
                                                    
                                                </div>

                                                {/* Category name with controlled text behavior */}
                                                <div className='mt-0 -mb-0 w-full'>
                                                    <span className='block text-center text-sm md:text-base capitalize max-w-full line-clamp-2 min-h-[3rem]'>
                                                        <p className="font-medium bg-slate-200"></p>
                                                    </span>
                                                </div>
                                            </div>
                                    </div>
                                )
                            })
                        ) : (
                            categoryProduct.map((product, index) => {
                                return (
                                    <div className='' >
                                        <Link  to={`/product-category?category=${encodeURIComponent(product?.category)}`} className='cursor-pointer' key={product?.category || index} >
                                            <div className='flex flex-col items-center justify-between w-20 h-auto md:w-28 md:h-auto  mt-2 bg-white rounded-md' >
                                                {/* Container for the image with fixed height */}
                                                <div className=' flex items-center justify-center'>
                                                    <span className='flex items-center text-center font-semibold text-sm md:text-base capitalize max-w-full line-clamp-2 min-h-[3rem]'>
                                                        {product?.category}
                                                    </span>
                                                </div>
                                                <div className='relative w-16 h-16 md:w-20 md:h-28 rounded overflow-hidden p-2 bg-white flex items-center justify-center '>
                                                    <img src={product?.productImage[0]} alt={product?.category} className='rounded-full h-full object-scale-down mix-blend-multiply hover:scale-125 transition-all' />
                                                </div>

                                                {/* Category name with controlled text behavior */}
                                                
                                            </div>
                                        </Link>
                                    </div>
                                
                                )
                            })
                        )
                    }
                </div>
          
        </div>
    </div>


  )
}

export default CategoryList


