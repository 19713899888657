const subCategories = {
    "Writing and Content Creation": ["Blog Writing", "Copywriting", "Technical Writing", "SEO Content", "Creative Writing", "Proofreading and Editing", "Product Descriptions", "Social Media Content"],
    "Graphic Design": ["Logo Design", "Brand Identity", "Print Design", "Web Design", "UI/UX Design", "Illustration", "Packaging Design", "Infographics"],
    "Web and Software Development": ["Frontend Development", "Backend Development", "Full Stack Development", "Mobile App Development", "Web App Development", "E-commerce Development", "WordPress Development", "CMS Development"],
    "Digital Marketing": ["SEO", "PPC Advertising", "Social Media Marketing", "Email Marketing", "Content Marketing", "Affiliate Marketing", "Influencer Marketing", "Marketing Strategy Consulting"],
    "Photography and Videography": ["Event Photography", "Portrait Photography", "Product Photography", "Real Estate Photography", "Video Editing", "Cinematography", "Drone Photography", "Wedding Photography"],
    "Consulting": ["Business Consulting", "Financial Consulting", "Marketing Consulting", "HR Consulting", "Management Consulting", "Strategy Consulting", "IT Consulting", "Legal Consulting"],
    "Education and Training": ["Online Tutoring", "Corporate Training", "Language Lessons", "Career Coaching", "Leadership Training", "Academic Coaching", "Soft Skills Development", "Test Preparation"],
    "Administrative Support": ["Virtual Assistant", "Data Entry", "Calendar Management", "Email Management", "Transcription Services", "Research Assistance", "Document Preparation", "Social Media Management"],
    "Art and Craft": ["Custom Artwork", "Handmade Jewelry", "Sculpture", "Painting", "Pottery", "Sewing and Tailoring", "Home Decor Crafts", "Upcycled Art"],
    "Health and Wellness": ["Fitness Coaching", "Nutrition Consulting", "Yoga Instruction", "Mental Health Counseling", "Meditation Coaching", "Massage Therapy", "Wellness Programs", "Personal Training"],
    "Technology and IT": ["Cloud Computing", "Cybersecurity", "IT Support", "Network Administration", "Software Testing", "System Administration", "Database Management", "Data Analysis"],
    "Finance and Accounting": ["Tax Consulting", "Accounting Services", "Financial Planning", "Investment Advice", "Bookkeeping", "Payroll Services", "Budgeting", "Forensic Accounting"],
    "Music and Audio": ["Music Production", "Audio Editing", "Voice-over Work", "Sound Design", "Podcast Production", "Music Composition", "Jingles and Commercial Music", "Mixing and Mastering"],
    "Real Estate Services": ["Property Management", "Real Estate Photography", "Real Estate Consulting", "Virtual Staging", "Property Listings", "Investment Advice", "Home Appraisal", "Market Analysis"]
};

export default subCategories